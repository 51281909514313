import { CarrierAccount, ShipViaDto } from 'types/shipVia';
import _uniq from 'lodash/uniq';

export function mapShipViaDtoToCarrierAccount(
  dto: ShipViaDto[]
): CarrierAccount[] {
  const carrierAccounts: CarrierAccount[] = [];

  dto.forEach((shipVia) => {
    shipVia.shipping_accounts.forEach((account) => {
      const exist = carrierAccounts.find(
        (ca) =>
          ca.carrierAccountUuid === account.carrier_account_uuid &&
          ca.countryCode === account.country_code &&
          ca.distributionCenterCode === account.distribution_center_code
      );
      if (exist) {
        exist.shipVias = _uniq(exist.shipVias.concat(shipVia.ship_via));
      } else {
        carrierAccounts.push({
          distributionCenterCode: account.distribution_center_code,
          countryCode: account.country_code,
          carrierAccountUuid: account.carrier_account_uuid,
          shipVias: [shipVia.ship_via],
        });
      }
    });
  });

  return carrierAccounts;
}
