import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { PurchaseOrderPreview } from 'types/purchaseOrders';

type Props = {
  data: PurchaseOrderPreview[];
};

export function AccordionPurchaseOrders({ data }: Props) {
  return (
    <Accordion width={['100%', '400px']} allowMultiple defaultIndex={[0]}>
      {data.map((po) => (
        <AccordionItem key={po.poNumber}>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              {po.poNumber}
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Text fontWeight={500}>Destination</Text>
            <UnorderedList fontWeight={300}>
              <ListItem>{po.warehouseID}</ListItem>
            </UnorderedList>
            <Text fontWeight={500}>Requested Delivery Date</Text>
            <UnorderedList fontWeight={300}>
              <ListItem>{po.requestedDate}</ListItem>
            </UnorderedList>
            <Text fontWeight={500}>SKUs</Text>
            <UnorderedList fontWeight={300}>
              {po?.lines?.map((item) => (
                <ListItem key={item.sku}>
                  {item.sku}, {item.orderQty} {item.unitOfMeasure}
                </ListItem>
              ))}
            </UnorderedList>
            <Text fontWeight={500}>Containers</Text>
            <UnorderedList fontWeight={300}>
              {po.containerNumbers?.map((container, index) => (
                <ListItem key={`container-${index}`}>
                  {container.containerNumber}
                </ListItem>
              ))}
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
}
