import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { ShipViaDto } from 'types/shipVia';
import { mapShipViaDtoToCarrierAccount } from './mappers';
import { useCallback, useMemo } from 'react';
import flatten from 'lodash-es/flatten';
import uniq from 'lodash-es/uniq';
import { filterShipVias } from 'utils/shipViaUtils';

export const RATE_SHOP_OPTION = 'RATE_SHOP';

export function useCarrierAccounts() {
  const queryState = useQuery(
    ['shipVias'],
    async () => {
      const {
        data: { items },
      } = await axios.get<{ items: ShipViaDto[] | undefined }>('/shipvias');

      return items ?? [];
    },
    { select: (data) => mapShipViaDtoToCarrierAccount(data) }
  );

  const getShipVias = useCallback(
    (props: {
      carrierAccountUUID?: string;
      warehouseID?: string;
      country?: string;
      isNetworkOrder?: boolean;
    }) => {
      return filterShipVias(queryState.data ?? [], props);
    },
    [queryState.data]
  );

  const allShipVias = useMemo(() => {
    return uniq(flatten(queryState.data?.map((item) => item.shipVias)));
  }, [queryState.data]);

  return {
    ...queryState,
    getShipVias,
    allShipVias,
  };
}
