import { FormSelect, FormSelectProps } from 'components/FormSelect';
import { useCarrierAccounts } from 'queries/shipVias/useCarrierAccounts';
import { RATE_SHOP_OPTION } from 'queries/shipVias/useCarrierAccounts';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

type Props = Omit<FormSelectProps, 'options'> & {
  includeRateShopOption?: boolean;
  isNetworkOrder?: boolean;
};

export function ShipViaSelect({
  includeRateShopOption = true,
  isNetworkOrder,
  ...props
}: Props) {
  const { register, watch } = useFormContext();

  const { getShipVias } = useCarrierAccounts();

  const carrierAccountUUID = watch('carrierAccountUUID');
  const warehouseID = watch('warehouseID');
  const country = watch('shipTo.country');

  const shipVias = useMemo(() => {
    const data = getShipVias({
      carrierAccountUUID,
      warehouseID,
      country,
      isNetworkOrder,
    })?.map((item) => ({
      value: item,
      label: item,
    }));

    if (!includeRateShopOption) {
      return data?.filter((item) => item.value !== RATE_SHOP_OPTION);
    }
    return data;
  }, [
    getShipVias,
    carrierAccountUUID,
    warehouseID,
    country,
    includeRateShopOption,
    isNetworkOrder,
  ]);

  return (
    <FormSelect
      {...props}
      {...register('shipVia')}
      key={`ship-via-${shipVias?.length}`}
      isRequired
      label="Ship Via"
      options={shipVias ?? []}
      tooltip="If set to Rate Shop, the cheapest ship method will be selected."
    />
  );
}
