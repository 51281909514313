import { HeadingContentFilterProps } from 'hooks/useGridFiltering';
import { ChangeEvent, HTMLProps, useMemo } from 'react';
import debounce from 'lodash-es/debounce';
import {
  Flex,
  FlexProps,
  Input,
  InputProps,
  Popover,
  PopoverTrigger,
  Icon,
} from '@chakra-ui/react';
import { Hidden } from 'components/Hidden';
import { FormDatePicker } from 'components/FormDatePicker';
import { DateRangePicker } from 'components/DateRangePicker';
import { getSelectOptions } from 'utils/tableUtils';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { MultiSelectionFilter } from './MultiSelectionFilter';
import { TEXT_QUERY_MIN_LENGTH } from 'constants/tableConfig';

export function HeadingInputControl({
  columnId,
  defaultFilterValue,
  columnMetaData,
  onFilterChange,
  onFilterHover,
  canFilter,
}: HeadingContentFilterProps & {
  columnId: string;
  onFilterHover?: (columnId: string) => void;
}) {
  const setFilter = useMemo(
    () =>
      debounce((updating, fieldToFocus: string = '') => {
        if (canFilter) {
          onFilterChange(columnId, updating, fieldToFocus);
        }
      }, 1000),
    [columnId, onFilterChange, canFilter]
  );

  const handleFilterChange = (
    event: ChangeEvent<HTMLInputElement>,
    fieldToFocus: string = ''
  ) => {
    const value = event.target.value;
    if (
      columnMetaData.dataType === 'text' &&
      !!value &&
      value?.length < TEXT_QUERY_MIN_LENGTH
    ) {
      return;
    }
    if (columnMetaData.dataType === 'number') {
      const minMax = defaultFilterValue?.split('_');
      const result =
        event.target.placeholder === 'Min'
          ? [value || (minMax[1] ? '0' : null), minMax[1]]
          : [minMax[0] || '0', value];
      setFilter(result.filter((num) => !!num).join('_'), fieldToFocus);
    } else setFilter(value, fieldToFocus);
  };

  const wrapperProps: FlexProps = {
    as: 'label',
    alignItems: 'center',
    justifyContent: 'space-between',
    title: 'Filter data',
  };

  const inputProps: Omit<HTMLProps<HTMLInputElement>, 'size' | 'defaultValue'> &
    Omit<InputProps, 'defaultValue'> & { defaultValue: string } = {
    variant: 'headingInput',
    onChange: (event: ChangeEvent<HTMLInputElement>) =>
      handleFilterChange(event, `${columnId}-data-grid-filter`),
    onMouseEnter: () => onFilterHover?.(columnId),
    name: `${columnId}-data-grid-filter`,
    size: 'xs',
    defaultValue: String(defaultFilterValue) || '',
  };

  if (columnMetaData.dataType === 'number') {
    const inputNameMin = `${columnId}-min-data-grid-filter`;
    const inputNameMax = `${columnId}-max-data-grid-filter`;
    return (
      <Flex {...wrapperProps} gap={1}>
        <Hidden>Filter</Hidden>
        <Input
          type="number"
          placeholder="Min"
          {...inputProps}
          name={inputNameMin}
          defaultValue={defaultFilterValue?.split('_')[0] || ''}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleFilterChange(event, inputNameMin)
          }
          readOnly={!canFilter}
        />
        <Input
          type="number"
          placeholder="Max"
          {...inputProps}
          name={inputNameMax}
          defaultValue={defaultFilterValue?.split('_')[1] || ''}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleFilterChange(event, inputNameMax)
          }
          readOnly={!canFilter}
        />
      </Flex>
    );
  }

  if (columnMetaData.dataType === 'date') {
    return (
      <Flex {...wrapperProps} as="span">
        <FormDatePicker
          variant={inputProps.variant}
          name={`${columnId}-data-grid-filter`}
          defaultValue={String(defaultFilterValue) || ''}
          onChange={(value: string) => setFilter(value)}
          onMouseEnter={inputProps.onMouseEnter}
          placeholder="Date"
          size="xs"
          iconSize="small"
          aria-label="Filter"
          isClearable
          readOnly={!canFilter}
        />
      </Flex>
    );
  }

  if (columnMetaData.dataType === 'date-range') {
    return (
      <Flex {...wrapperProps} as="span">
        <DateRangePicker
          useUTC={columnMetaData.useUTC}
          variant={inputProps.variant}
          dateFormat="M/d/yy"
          name={`${columnId}-data-grid-filter`}
          defaultValue={String(defaultFilterValue) || ''}
          onChange={(value: string) => setFilter(value)}
          onMouseEnter={inputProps.onMouseEnter}
          placeholderText="Date Range"
          size="xs"
          aria-label="Filter"
          isClearable
          readOnly={!canFilter}
        />
      </Flex>
    );
  }

  if (columnMetaData.dataType === 'select') {
    const defaultSelectValues = defaultFilterValue
      ? defaultFilterValue.split(',')
      : getSelectOptions(columnMetaData.selectOptions);

    const activeColor =
      defaultSelectValues.length < (columnMetaData.selectOptions?.length ?? 0)
        ? 'secondaryOrange.500'
        : '';

    return (
      <Flex {...wrapperProps}>
        <Popover>
          <PopoverTrigger>
            <Input
              as="button"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              color={activeColor}
              borderColor={activeColor}
              readOnly={!canFilter}
              {...inputProps}
            >
              Select ({defaultSelectValues.length})
              <Icon as={ArrowDropDownIcon} color="currentcolor" />
            </Input>
          </PopoverTrigger>
          {defaultSelectValues.length > 0 && (
            <MultiSelectionFilter
              columnId={columnId}
              defaultSelectedValues={defaultSelectValues ?? []}
              columnMetaData={columnMetaData}
              onFilterChange={onFilterChange}
              canFilter={canFilter}
            />
          )}
        </Popover>
      </Flex>
    );
  }

  return (
    <Flex {...wrapperProps}>
      <Hidden>Search</Hidden>
      <Input
        type="search"
        placeholder="Search"
        {...inputProps}
        readOnly={!canFilter}
      />
    </Flex>
  );
}
