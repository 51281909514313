import { Button, ButtonProps } from '@chakra-ui/react';

export function ActionButton({ children, ...props }: ButtonProps) {
  return (
    <Button
      fontSize="md"
      variant="redSquareButton"
      rounded="md"
      h="24px"
      w="24px"
      minW="24px"
      p="0"
      {...props}
    >
      {children}
    </Button>
  );
}
