import { GridItem, ResponsiveValue, SimpleGrid } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { FormInput } from 'components/FormInput';
import CountrySelect from './CountrySelect';

type Props = {
  startTabIndex: number;
  variant?: ResponsiveValue<string>;
};

export const ShipToForm: React.FC<Props> = ({
  startTabIndex,
  variant = 'flushed',
}) => {
  const { register, watch } = useFormContext();

  const country = watch('shipTo.country');

  return (
    <SimpleGrid columns={[1, 1, 2]} columnGap={8} aria-label="Ship To">
      <GridItem colSpan={[1, 1, 2]}>
        <FormInput
          variant={variant}
          tabIndex={startTabIndex + 1}
          label="Name"
          {...register('shipTo.name')}
          isRequired
        />
      </GridItem>
      <FormInput
        variant={variant}
        tabIndex={startTabIndex + 2}
        label="Address Line 1"
        {...register('shipTo.address')}
        isRequired
      />
      <FormInput
        variant={variant}
        tabIndex={startTabIndex + 3}
        label="Address Line 2"
        {...register('shipTo.address2')}
      />
      <FormInput
        variant={variant}
        tabIndex={startTabIndex + 4}
        label="City"
        {...register('shipTo.city')}
        isRequired
      />
      <FormInput
        variant={variant}
        isRequired={country === 'US'}
        tabIndex={startTabIndex + 5}
        label="State"
        {...register('shipTo.state')}
      />
      <FormInput
        variant={variant}
        tabIndex={startTabIndex + 6}
        label="ZIP/Postal Code"
        {...register('shipTo.zipCode')}
        isRequired
      />
      <CountrySelect variant={variant} tabIndex={startTabIndex + 7} />
      <FormInput
        variant={variant}
        isRequired={country !== 'US'}
        tabIndex={startTabIndex + 8}
        label="Customer Telephone"
        {...register('shipTo.phoneNumber')}
      />
      <FormInput
        variant={variant}
        tabIndex={startTabIndex + 9}
        label="Customer Email"
        {...register('shipTo.email')}
      />
    </SimpleGrid>
  );
};
