import {
  TableContainer,
  Tbody,
  Thead,
  Table,
  Tr,
  Th,
  Td,
  TableContainerProps,
  Box,
  Flex,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useRef } from 'react';
import {
  Column,
  Row,
  useTable,
  useBlockLayout,
  useResizeColumns,
  UseResizeColumnsState,
  HeaderGroup,
  TableHeaderGroupProps,
  useFilters,
  UseFiltersColumnOptions,
  useSortBy,
  useExpanded,
} from 'react-table';

/* Components */
import { ExceptionState, ExceptionStateProps } from './ExceptionState';
import { GridHeadingContent } from './GridHeadingContent/GridHeadingContent';
import { PagePicker } from './PagePicker';
import { PerPageQtyPicker } from './PerPageQtyPicker';

/* Types */
import {
  ColumnMetaData,
  GridFilteringHookResult,
} from 'hooks/useGridFiltering';
import { GridPaginationHookResult } from 'hooks/useGridPagination';
import { GridSortingHookResult } from 'hooks/useGridSorting';
import { InventoryQueryResult } from 'queries/inventory/useInventory';
import { OrdersQueryResult } from 'queries/salesOrders';
import { PurchaseOrderQueryResult } from 'queries/purchaseOrders/usePurchaseOrders';
import { Shimmer } from './Shimmer';
import { ErrorBoundary } from './ErrorBoundary';
import {
  persistTableColumnWidths,
  persistTableHiddenColumns,
  retrieveTableColumnWidths,
  retrieveTableHiddenColumns,
} from 'utils/storageUtils';
import SharedStyles from 'styles/shared.module.css';
import { QueryState } from 'types/queryState';
import AsteriskIcon from './AsteriskIcon';
import { filterDate, filterDateRange, filterSelect } from 'utils/tableUtils';
import { parseValueForClientSideFilter } from 'utils/urlSearchParamsUtils';
import { TableContextProvider } from 'contexts/tableContext';

export type ColumnWithFilter<T extends object> = Column<T> &
  Partial<UseFiltersColumnOptions<T>>;

interface PaginatedTableProps extends TableContainerProps {
  columns: ColumnWithFilter<any>[];
  queryState:
    | InventoryQueryResult
    | OrdersQueryResult
    | PurchaseOrderQueryResult
    | QueryState<any>;
  gridPagination?: GridPaginationHookResult;
  gridSorting?: GridSortingHookResult<any>;
  gridFiltering?: GridFilteringHookResult<any>;
  tableName: string;
  hiddenColumns?: Array<string>;
  isResizable?: boolean;
  isSticky?: boolean;
  isRequired?: boolean;
  clientSide?: boolean;
  hideHeader?: boolean;
  renderSubRow?: (row: Row<any>) => React.ReactNode;
}

type ExtendedColumn = Column & {
  getValue?: (value: any) => string;
  helpText?: string;
  isRequired?: boolean;
};

const ResizerProps: Record<string, string> = {
  bgColor: '#c9c8cB',
  height: '20px',
  width: '1px',
};

export const PaginatedTable = ({
  columns,
  queryState,
  gridPagination,
  gridSorting,
  gridFiltering,
  tableName,
  hiddenColumns,
  isResizable,
  isSticky,
  clientSide,
  hideHeader,
  renderSubRow,
  ...props
}: PaginatedTableProps) => {
  const tableColumnWidths = useRef<(number | string)[]>([]);
  const tableColumnMinWidths = useRef<(number | string)[]>([]);
  const persistedColumnWidths = retrieveTableColumnWidths(tableName);
  const persistedHiddenColumns = useMemo(() => {
    const cachedHiddenColumns = retrieveTableHiddenColumns(tableName);
    if (!cachedHiddenColumns.length) {
      persistTableHiddenColumns(tableName, hiddenColumns ?? []);
      return hiddenColumns ?? [];
    }
    return cachedHiddenColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data: any[] = queryState?.data ?? [];

  const columnsWithFilter = useMemo(
    () =>
      columns.map((column) => {
        const columnId =
          typeof column.accessor === 'string'
            ? column.accessor
            : column.id ?? '';
        const defaultDataType: ColumnMetaData = { dataType: 'text' };
        const { dataType } =
          gridFiltering?.getColumnMetaData(columnId) ?? defaultDataType;

        let filter: any = 'text';
        if (column.filter) filter = column.filter;
        else if (dataType === 'select') filter = filterSelect;
        else if (dataType === 'number') filter = 'between';
        else if (dataType === 'date') filter = filterDate;
        else if (dataType === 'date-range') filter = filterDateRange;

        return {
          ...column,
          id: columnId,
          filter,
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const defaultFilters = useMemo(() => {
    if (!clientSide || !gridFiltering) return [];
    return Object.entries(gridFiltering.filters ?? {}).map(([id, value]) => {
      const columnMetadata = gridFiltering.getColumnMetaData(id);
      return {
        id,
        value: columnMetadata
          ? parseValueForClientSideFilter(columnMetadata.dataType, value)
          : value,
      };
    });
  }, [gridFiltering, clientSide]);

  const defaultSortBy = useMemo(() => {
    if (!clientSide || !gridSorting?.sort?.field) return [];
    return [
      {
        id: gridSorting.sort.field,
        desc: gridSorting.sort.direction === 'desc',
      },
    ];
  }, [gridSorting, clientSide]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    allColumns,
  } = useTable(
    {
      defaultColumn: {
        maxWidth: 500,
        minWidth: 50,
        width: 100,
      },
      columns: clientSide ? columnsWithFilter : columns,
      data: data,
      getSubRows: () => [],
      initialState: {
        sortBy: defaultSortBy,
        filters: defaultFilters,
        columnResizing: {
          columnWidths: persistedColumnWidths,
        },
        hiddenColumns: persistedHiddenColumns,
      } as any,
      autoResetHiddenColumns: true,
    } as any,
    useFilters,
    useSortBy,
    useBlockLayout,
    useResizeColumns,
    useExpanded
  );

  const visibleColumnSettings = useMemo(() => {
    return allColumns?.filter(
      (column) =>
        !gridFiltering?.getColumnMetaData(column.id).hideSettingsButton
    );
  }, [gridFiltering, allColumns]);

  const tableHasNoData =
    (!queryState.isLoading && data.length === 0) ||
    (clientSide && rows.length === 0 && !queryState.isLoading);

  const BaseExceptionState = (props: ExceptionStateProps) => (
    <ExceptionState
      w={['100%', null, '800px']}
      my={3}
      mx="auto"
      isCenteredOnMobile={true}
      {...props}
    />
  );

  const EmptyState = () => (
    <BaseExceptionState
      stateType="empty"
      customErrorMessage="No results. Remove filters to show more data."
    />
  );

  const NetworkErrorState = () => (
    <BaseExceptionState stateType="error" retryHandler={queryState.refetch} />
  );

  const renderTableHeader = (
    headerGroup: HeaderGroup,
    { style, ...headerProps }: TableHeaderGroupProps
  ) => {
    return (
      <Tr {...headerProps} style={{ ...style, width: '100%' }}>
        {headerGroup.headers.map((column, i) =>
          renderTableHeaderColumn(column, i)
        )}
      </Tr>
    );
  };

  const renderTableHeaderColumn = (
    column: HeaderGroup,
    columnPosition: number
  ) => {
    const width = persistedColumnWidths[column.id] || column.width || '';
    tableColumnWidths.current[columnPosition] = width;
    tableColumnMinWidths.current[columnPosition] =
      columns[columnPosition].width || '';
    const { isRequired } = column as ExtendedColumn;

    if (gridSorting && gridFiltering) {
      return (
        <Th
          {...column.getHeaderProps({
            ...gridSorting.getHeadingSortProps(column),
            style: {
              minWidth: columns[columnPosition].width,
              overflow: 'hidden',
              position: 'initial',
              width,
            },
          })}
        >
          <ErrorBoundary size="compact">
            <GridHeadingContent
              clientSide={clientSide}
              {...gridSorting.getHeadingContentSortProps(column)}
              {...gridFiltering.getHeadingContentFilterProps(column)}
              columnHelpText={(column as ExtendedColumn).helpText}
              tableName={tableName}
              resizeElement={
                isResizable ? (
                  <Box {...column.getResizerProps()} {...ResizerProps} />
                ) : null
              }
              isLoading={queryState.isLoading}
            >
              {column.render('Header')}
            </GridHeadingContent>
          </ErrorBoundary>
        </Th>
      );
    }

    return (
      <Th
        {...column.getHeaderProps({
          style: {
            display: 'flex',
            minWidth: columns[columnPosition].width,
            overflow: 'hidden',
            position: 'initial',
            width,
          },
        })}
        justifyContent="space-between"
      >
        <Box display="flex">
          {column.render('Header')}
          {isRequired ? <AsteriskIcon /> : null}
        </Box>
        {isResizable ? (
          <Box {...column.getResizerProps()} {...ResizerProps} />
        ) : null}
      </Th>
    );
  };

  const isExceptionState = !!queryState.error || data.length === 0;
  const totalPages = gridPagination?.getTotalPages(queryState.filteredCount);
  const start = gridPagination?.getPageStart(data);
  const end = gridPagination?.getPageEnd(data);

  useEffect(() => {
    if ((state as UseResizeColumnsState<any>)?.columnResizing?.columnWidths) {
      if (
        Object.keys(
          (state as UseResizeColumnsState<any>).columnResizing.columnWidths
        ).length > 0
      ) {
        persistTableColumnWidths(
          tableName,
          (state as UseResizeColumnsState<any>).columnResizing.columnWidths
        );
      }
    }
  }, [tableName, state]);

  return (
    <TableContextProvider
      value={{
        tableName,
        defaultHiddenColumns: hiddenColumns,
        columnSettings: visibleColumnSettings,
        persistedHiddenColumns,
      }}
    >
      <Box>
        <TableContainer
          sx={{
            '&::-webkit-scrollbar': {
              height: '10px',
            },
            '&::-webkit-scrollbar-track': {
              height: '14px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'darkgrey',
            },
          }}
          mb={2}
          {...props}
        >
          <Table
            {...getTableProps()}
            variant="main-grid"
            width="auto"
            mb={1}
            aria-busy={queryState.isLoading}
          >
            {!hideHeader ? (
              <Thead
                className={isSticky ? SharedStyles['sticky-header'] : undefined}
              >
                {headerGroups.map((headerGroup) => {
                  return renderTableHeader(
                    headerGroup,
                    headerGroup.getHeaderGroupProps()
                  );
                })}
              </Thead>
            ) : null}
            <Tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                const { style, ...rowProps } = row.getRowProps();
                const isExpanded = Boolean(
                  (row as Row<object> & { isExpanded: boolean }).isExpanded
                );
                return (
                  <React.Fragment key={row.index}>
                    <Tr {...rowProps} style={{ ...style, width: '100%' }}>
                      {row.cells.map((cell, columnIndex) => {
                        const { style, ...cellProps } = cell.getCellProps();
                        const getValue = (
                          columns[columnIndex] as ExtendedColumn
                        ).getValue;

                        return (
                          <Td
                            {...cellProps}
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            overflow="hidden"
                            title={getValue ? getValue(cell.value) : cell.value}
                            colSpan={1}
                            style={{
                              ...style,
                              minWidth: columns[columnIndex].width,
                              width: tableColumnWidths.current[columnIndex],
                            }}
                          >
                            <ErrorBoundary size="compact">
                              {cell.render('Cell')}
                            </ErrorBoundary>
                          </Td>
                        );
                      })}
                    </Tr>
                    {renderSubRow && isExpanded ? (
                      <Tr>
                        <Td p={0} colSpan={row.cells.length}>
                          {renderSubRow(row)}
                        </Td>
                      </Tr>
                    ) : null}
                  </React.Fragment>
                );
              })}
              {queryState.isLoading &&
                new Array(gridPagination?.pageSize).fill('').map((_, i) => (
                  <Tr key={i}>
                    {headerGroups[0].headers.map((_, j) => (
                      <Td
                        display="inline-block"
                        colSpan={1}
                        key={j}
                        style={{
                          width: tableColumnWidths.current[j],
                          minWidth: tableColumnMinWidths.current[j],
                        }}
                      >
                        <Shimmer w={`${((i + j) % 5) * 10 + 50}%`} h="20px" />
                      </Td>
                    ))}
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>

        {!!queryState.error ? (
          <NetworkErrorState />
        ) : tableHasNoData ? (
          <EmptyState />
        ) : null}

        {!isExceptionState &&
          !queryState.isLoading &&
          gridPagination &&
          totalPages && (
            <Flex
              justifyContent="space-between"
              alignItems={{ md: 'flex-end', lg: 'center' }}
              flexDirection={['column', null, 'row']}
            >
              <ErrorBoundary size="compact">
                <PerPageQtyPicker
                  quantity={gridPagination.pageSize}
                  setQuantity={gridPagination.setPageSize}
                />
                <Flex
                  justifyContent="space-between"
                  flexDirection={{ base: 'row', md: 'column', lg: 'row' }}
                  mt={[2, 0]}
                >
                  <Text
                    alignSelf={['start', null, 'end']}
                    mb={{ base: 0, md: 2, lg: 0 }}
                    mr={{ base: 0, lg: 4 }}
                  >
                    {(isExceptionState || queryState.isLoading) &&
                    gridPagination ? (
                      <>Showing 0 of 0</>
                    ) : start && end ? (
                      <>
                        Showing {start}-{end} of {queryState.filteredCount}
                      </>
                    ) : null}
                  </Text>
                  <PagePicker
                    page={gridPagination.page}
                    setPage={gridPagination.setPage}
                    totalPages={totalPages}
                  />
                </Flex>
              </ErrorBoundary>
            </Flex>
          )}
      </Box>
    </TableContextProvider>
  );
};
