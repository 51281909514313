import { Button, Flex, Text, VStack } from '@chakra-ui/react';
import { useMemo } from 'react';
import { ItemMasterCustomsInfo } from 'types/itemMaster';
import { AccordionItemMasters } from './components/AccordionItemMasters';

type Props = {
  isLoading?: boolean;
  data: ItemMasterCustomsInfo[];
  onBack: () => void;
  onConfirm: () => void;
};

export function PreviewItemMasters({
  isLoading,
  data,
  onBack,
  onConfirm,
}: Props) {
  const newSkus = useMemo(() => {
    return data.filter((item) => !item.isValidItemMaster);
  }, [data]);

  const updatedSkus = useMemo(() => {
    return data.filter((item) => item.isValidItemMaster);
  }, [data]);

  const previewText = useMemo(() => {
    let text = '';
    if (newSkus.length === 0 && updatedSkus.length === 0) {
      return '';
    }
    if (newSkus.length > 0) {
      text += `${newSkus.length} SKU(s) will be created.`;
    }
    if (updatedSkus.length > 0) {
      text += ` ${updatedSkus.length} SKU(s) will be updated.`;
    }
    return `${text.trim()} Confirm your changes to continue.`;
  }, [newSkus, updatedSkus]);

  return (
    <VStack align="start" spacing={3}>
      <Text as="h3" variant="pageHeading" my={1}>
        3. Preview Changes
      </Text>
      <Text fontWeight={500}>{previewText}</Text>
      <AccordionItemMasters data={data} />
      <Flex mt={4} gap={3}>
        <Button
          isDisabled={isLoading}
          size="md"
          colorScheme="primaryBlue"
          onClick={onBack}
        >
          Back
        </Button>
        <Button
          isLoading={isLoading}
          size="md"
          variant="solid"
          colorScheme="primaryBlue"
          onClick={onConfirm}
        >
          Confirm
        </Button>
      </Flex>
    </VStack>
  );
}
