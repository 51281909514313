import { SpaceProps } from '@chakra-ui/react';
import { PurchaseOrder, PurchaseOrderLine } from 'types/purchaseOrders';

import { useMemo } from 'react';
import { PaginatedTable } from 'components/PaginatedTable';
import { QueryState } from 'types/queryState';

interface Props extends SpaceProps {
  order?: PurchaseOrder;
}

export function PurchaseOrderLinesTable({ order }: Props) {
  const purchaseOrderColumns = useMemo(
    () => [
      {
        Header: 'Line #',
        accessor: 'lineNo',
        width: 80,
      },
      {
        Header: 'SKU',
        accessor: 'sku',
        width: 142,
      },
      {
        Header: 'Customer Item #',
        accessor: 'customerItemNumber',
        width: 200,
      },
      {
        Header: 'Description',
        accessor: 'description',
        width: 200,
      },
      {
        Header: 'Qty',
        accessor: 'orderQty',
        width: 90,
        Cell: ({
          value: orderQty,
          row,
        }: {
          value: string;
          row: { unitOfMeasure: string };
        }) => (
          <>
            {orderQty} {row.unitOfMeasure}
          </>
        ),
      },
      {
        Header: 'Received',
        accessor: 'receivedQty',
        width: 100,
        Cell: ({
          value: receivedQty,
          row,
        }: {
          value: string;
          row: { unitOfMeasure: string };
        }) => (
          <>
            {receivedQty} {row.unitOfMeasure}
          </>
        ),
      },
      {
        Header: 'Short',
        accessor: 'shortQty',
        width: 90,
        Cell: ({
          value: shortQty,
          row,
        }: {
          value: string;
          row: { unitOfMeasure: string };
        }) => (
          <>
            {shortQty} {row.unitOfMeasure}
          </>
        ),
      },
      {
        Header: 'Damaged',
        accessor: 'damagedQty',
        width: 120,
        Cell: ({
          value: damagedQty,
          row,
        }: {
          value: string;
          row: { unitOfMeasure: string };
        }) => (
          <>
            {damagedQty} {row.unitOfMeasure}
          </>
        ),
      },
      {
        Header: 'Overage',
        accessor: 'overageQty',
        width: 110,
        Cell: ({
          value: overageQty,
          row,
        }: {
          value: string;
          row: { unitOfMeasure: string };
        }) => (
          <>
            {overageQty} {row.unitOfMeasure}
          </>
        ),
      },
    ],
    []
  );

  return (
    <PaginatedTable
      columns={purchaseOrderColumns as any}
      queryState={
        {
          data: order?.lines || [],
          isLoading: !order,
        } as QueryState<PurchaseOrderLine>
      }
      tableName="purchase_order_lines_table"
      isResizable
      isSticky
    />
  );
}
