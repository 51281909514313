import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { itemMasterFieldToQuery } from 'features/item-master-list/itemMasterPageParams';
import { PydanticValidationError } from 'hooks/useCrudActionUserFeedback';
import { SortState } from 'hooks/useGridSorting';
import { ItemMaster } from 'types/itemMaster';
import { FileType } from 'utils/fileTypes';
import { downloadReport } from 'utils/reportUtils';
import {
  parseAdvancedFilters,
  querifyFiltersForApi,
  querifySort,
} from 'utils/urlSearchParamsUtils';

export type ItemMastersExportArgs = {
  sort: SortState<ItemMaster>;
  filters: { [columnId: string]: string };
  update: boolean;
};
export function useItemMastersExport() {
  return useMutation({
    mutationFn: async ({ filters, sort, update }: ItemMastersExportArgs) => {
      const sortQuery = querifySort(sort, itemMasterFieldToQuery)
        .replace('-range', '')
        .replace('-select', '');
      const filtersQuery = parseAdvancedFilters(
        querifyFiltersForApi(filters as any, itemMasterFieldToQuery)
      );

      let errorMsg = '';
      try {
        const response = await axios.get('/itemmaster/csv', {
          params: {
            sort: sortQuery,
            ...filtersQuery,
            update,
          },
        });
        if (typeof response.data === 'string') {
          const filename = (response.headers['content-disposition'] as string)
            ?.split('filename=')
            ?.at(-1);
          downloadReport({
            fileName: filename,
            data: response.data,
            fileType: FileType.Csv,
          });
          return response.data;
        } else {
          errorMsg =
            response.data.message ??
            'Failed to download report. Please try again.';
        }
      } catch (error: any) {
        if (axios.isAxiosError(error)) {
          const detail =
            (error as PydanticValidationError).response?.data?.detail ?? [];
          errorMsg =
            detail.map((detail: any) => detail.msg).join('\n') ??
            error?.message;
        } else {
          errorMsg = error?.message;
        }
      }
      if (errorMsg) {
        throw new Error(errorMsg);
      }
    },
  });
}
