import { Box, SimpleGrid, Text } from '@chakra-ui/react';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { InlineText } from 'components/InlineText';
import { Shimmer } from 'components/Shimmer';
import { PurchaseOrder, PurchaseOrderStatus } from 'types/purchaseOrders';
import { PurchaseOrderLinesTable } from './components/ViewPurchaseOrder/PurchaseOrderLinesTable';
import { isChangeable } from 'utils/purchaseOrdersUtils';
import { ButtonGroup } from 'components/ButtonGroup';
import { StatusChangeList } from 'features/order-details/components/ViewOrder/StatusChangeList';
import { useMemo } from 'react';
import { Address } from 'features/order-details/components/ViewOrder/Address';
import { ContainerStatusList } from './components/ViewPurchaseOrder/ContainerStatusList';
import { mapContainerStatusList } from 'queries/purchaseOrders/mappers';
import { Appointments } from './components/ViewPurchaseOrder/Appointments';
import { useToast } from 'hooks/useToast';

type Props = {
  order?: PurchaseOrder;
  onCancel: () => void;
  onEdit: () => void;
};

export function PurchaseOrderDetails({ order, onEdit, onCancel }: Props) {
  const toast = useToast();
  const currentStatus = order?.statusChangesHistory?.at(-1);

  const latestStatusColor = useMemo(() => {
    if (currentStatus?.status === PurchaseOrderStatus.Received) return 'green';
    return 'gray';
  }, [currentStatus]);

  return (
    <>
      <Text mb={6} as="h2" variant="pageTitle">
        Inbound Shipping Plan #{order?.poNumber}
      </Text>
      <SimpleGrid columns={{ base: 1, sm: 2, lg: 3 }} gap={3}>
        <ErrorBoundary>
          <StatusChangeList
            history={order?.statusChangesHistory}
            latestStatusColor={latestStatusColor}
          />
        </ErrorBoundary>

        <Box>
          <Text variant="boldBlue" fontSize="xl" mr={1}>
            Fulfillment Center
          </Text>
          <Shimmer
            w="160px"
            h="30px"
            display="inline-block"
            verticalAlign="top"
            isLoaded={!!order}
          >
            <InlineText display={['block', null, 'inline']}>
              {order?.warehouseID}
            </InlineText>
          </Shimmer>
          <Address address={order?.deliveryAddress} />
          <Appointments order={order} />
        </Box>

        <ContainerStatusList
          data={
            order
              ? mapContainerStatusList(
                  order.containerNumbers,
                  order.containerStatus
                )
              : undefined
          }
        />
      </SimpleGrid>
      <ErrorBoundary>
        <PurchaseOrderLinesTable order={order} mt={30} />
      </ErrorBoundary>
      <ButtonGroup
        wrapperProps={{
          mt: 8,
          display: 'grid',
          columnGap: 8,
          rowGap: 4,
          gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        }}
        commonButtonWrapperProps={{ mr: 0, colSpan: 1 }}
        commonButtonProps={{ width: '100%' }}
        buttons={[
          {
            label: 'Modify',
            handler: onEdit,
            visible: order && isChangeable(order),
            isDisabled: !order?.warehouseID,
          },
          {
            label: 'Cancel',
            handler: onCancel,
            disabledStateHandler: () =>
              toast({
                status: 'error',
                title: 'Error',
                description:
                  'Cannot cancel at this time as the order has been received.',
              }),
            isDisabled: !order || !isChangeable(order),
          },
        ]}
      />
    </>
  );
}
