import { Text, VStack } from '@chakra-ui/react';
import {
  ItemMasterUploadErrorV2,
  ItemMasterCustomsInfo,
} from 'types/itemMaster';
import { ErrorUploadItemMasters } from './components/ErrorUploadItemMasters';
import { SuccessUploadItemMasters } from './components/SuccessUploadItemMasters';

type Props = {
  message?: string;
  data: ItemMasterCustomsInfo[];
  error: ItemMasterUploadErrorV2[] | null;
  onBack: () => void;
};

export function ReviewItemMasters({ message, data, error, onBack }: Props) {
  return (
    <VStack align="start" spacing={3}>
      <Text as="h3" variant="pageHeading" my={1}>
        4. Review Results
      </Text>
      {error ? (
        <ErrorUploadItemMasters error={error} onBack={onBack} />
      ) : (
        <SuccessUploadItemMasters data={data} message={message} />
      )}
    </VStack>
  );
}
