import {
  Stack,
  Text,
  TextProps,
  StackProps,
  IconButton,
} from '@chakra-ui/react';
import MailToIcon from '@mui/icons-material/ContentCopy';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { useState } from 'react';

interface CopyableTextProps extends StackProps {
  children?: React.ReactNode;
  text: string;
  copiedMessage: string;
  isExpandable?: boolean;
  titleButton?: string;
  textProps?: TextProps;
}

export const CopyableText: React.FC<CopyableTextProps> = ({
  children,
  text,
  copiedMessage,
  isExpandable,
  titleButton,
  textProps,
  ...props
}) => {
  const [isExpanding, setIsExpanding] = useState(false);
  const { copy } = useCopyToClipboard(text, {
    toastOptions: {
      status: 'info',
      description: copiedMessage,
    },
  });

  return (
    <Stack direction="row" spacing={0.5} {...props}>
      <Text
        fontWeight="medium"
        sx={{
          maxHeight: isExpandable && isExpanding ? 'none' : '20px',
          overflow: isExpandable && isExpanding ? '' : 'hidden',
          whiteSpace: isExpandable && isExpanding ? 'normal' : 'nowrap',
          textOverflow: isExpandable && isExpanding ? '' : 'ellipsis',
          ...textProps?.sx,
        }}
        {...textProps}
      >
        {children ?? text}
      </Text>
      {isExpandable && (
        <IconButton
          size="xs"
          variant="ghost"
          aria-label="Expand"
          title={!isExpanding ? 'Click to Expand' : 'Click to Collapse'}
          icon={
            !isExpanding ? (
              <KeyboardArrowDownIcon fontSize="small" />
            ) : (
              <KeyboardArrowUpIcon fontSize="small" />
            )
          }
          onClick={() => setIsExpanding(!isExpanding)}
        />
      )}
      {Boolean(text.trim()) && (
        <IconButton
          size="xs"
          variant="ghost"
          title={titleButton ? titleButton : text}
          aria-label="Copy"
          icon={<MailToIcon fontSize="small" />}
          onClick={copy}
        />
      )}
    </Stack>
  );
};
