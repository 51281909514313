import get from 'lodash-es/get';
import isEqual from 'lodash-es/isEqual';
import { useCallback, useEffect, useRef, useState } from 'react';
import { HeaderGroup, UseFiltersColumnProps } from 'react-table';
import { QueryParamsObject } from 'utils/urlSearchParamsUtils';

export function useGridFiltering<T extends object>({
  columnsMetaData,
  initialFilters,
  onFilterChange,
}: GridFilteringHookOptions): GridFilteringHookResult<T> {
  const getColumnMetaData: (columnId: string) => ColumnMetaData = (columnId) =>
    columnsMetaData[columnId] || {
      dataType: 'text',
    };

  const getColumnDefaultValue: (columnId: string) => string = (columnId) =>
    get(initialFilters, columnId, '');

  const [filters, setFilters] = useState<QueryParamsObject>(
    initialFilters || {}
  );
  const updateFilters = useCallback(
    (columnId: string, value: string, fieldToFocus: string = '') => {
      const patching = {
        [columnId]: value,
        fieldToFocus: fieldToFocus,
      };
      setFilters((previous) => ({ ...previous, ...patching }));
    },
    []
  );

  const lastFilters = useRef<QueryParamsObject>(initialFilters || {});
  useEffect(() => {
    if (onFilterChange && !isEqual(lastFilters.current, filters)) {
      onFilterChange(filters);
      lastFilters.current = filters;
    }
  }, [filters, onFilterChange]);

  return {
    getHeadingContentFilterProps: (column) => ({
      columnMetaData: getColumnMetaData(column.id),
      defaultFilterValue: getColumnDefaultValue(column.id),
      onFilterChange: updateFilters,
      canFilter: column.canFilter,
      setFilter: column.setFilter,
      filterValue: column.filterValue,
    }),
    filters,
    getColumnMetaData,
  };
}

export type ColumnDataType =
  | 'text'
  | 'select'
  | 'date'
  | 'number'
  | 'date-range';

export type Option = { label: string; value: string };
export interface ColumnMetaData {
  dataType: ColumnDataType;
  selectOptions?: Array<string> | Array<Option>;
  formatOptions?: boolean;
  isSortable?: boolean;
  isFilterable?: boolean;
  hideSettingsButton?: boolean;
  useUTC?: boolean;
}

export interface HeadingContentFilterProps {
  columnMetaData: ColumnMetaData;
  defaultFilterValue: string;
  onFilterChange: (
    columnId: string,
    value: string,
    fieldToFocus?: string
  ) => void;
  canFilter?: boolean;
  setFilter?: (updater: ((filterValue: any) => any) | any) => void;
  filterValue?: any;
}

interface GridFilteringHookOptions {
  columnsMetaData: { [columnId: string]: ColumnMetaData };
  initialFilters?: QueryParamsObject;
  onFilterChange?: (filters: { [columnId: string]: string }) => void;
  clientSideFilter?: boolean;
}

export interface GridFilteringHookResult<T extends object> {
  getHeadingContentFilterProps: (
    column: HeaderGroup<T> & Partial<UseFiltersColumnProps<T>>
  ) => HeadingContentFilterProps;
  filters: { [columnId: string]: string };
  getColumnMetaData: (columnId: string) => ColumnMetaData;
}
