import {
  Box,
  Button,
  Flex,
  ListItem,
  Tag,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { PaginatedTable } from 'components/PaginatedTable';
import { useLayoutPaginatedTable } from 'hooks/useLayoutPaginatedTable';
import { useMemo } from 'react';
import { Column } from 'react-table';
import { QueryState } from 'types/queryState';
import _omit from 'lodash-es/omit';
import { ItemMasterUploadErrorV2 } from 'types/itemMaster';
import { stringify } from 'csv-stringify/browser/esm/sync';
import { downloadReport } from 'utils/reportUtils';
import { FileType } from 'utils/fileTypes';

export type Props = {
  error: ItemMasterUploadErrorV2[];
  onBack: () => void;
};

export function ErrorUploadItemMasters({ error, onBack }: Props) {
  const { getColumnWidth } = useLayoutPaginatedTable(800);

  const getRowDetail = (error: ItemMasterUploadErrorV2) => {
    return (
      Object.values(_omit(error, ['errors'])).join(', ') || 'Request Error'
    );
  };

  const columns = useMemo(() => {
    return [
      {
        Header: 'Row',
        accessor: 'sku',
        width: getColumnWidth(40, 400),
        Cell: ({ row }) => (
          <Box whiteSpace="normal">{getRowDetail(row.original)}</Box>
        ),
      },
      {
        Header: 'Error Message',
        accessor: 'errors',
        width: getColumnWidth(60, 400),
        Cell: ({ value }) => (
          <UnorderedList listStyleType="none" m={0}>
            {value?.map((err, index) => (
              <ListItem key={index} whiteSpace="normal">
                {err}
              </ListItem>
            ))}
          </UnorderedList>
        ),
      },
    ] as Column<ItemMasterUploadErrorV2>[];
  }, [getColumnWidth]);

  const downloadErrors = () => {
    const csvData = error.map((err) => ({
      Row: getRowDetail(err),
      'Error Message': err.errors.map((err) => err).join('\n'),
    }));
    downloadReport({
      fileName: 'upload-item-master-errors.csv',
      data: stringify(csvData, {
        header: true,
      }),
      fileType: FileType.Csv,
    });
  };

  return (
    <>
      <Text
        fontWeight={500}
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        gap={2}
      >
        <Tag
          as="span"
          size="lg"
          fontSize="lg"
          variant="solid"
          colorScheme="red"
        >
          Errors Found
        </Tag>
        No SKUs were uploaded. Please correct the errors and reupload the file.
      </Text>
      <Box maxW="100%" overflow="auto">
        <PaginatedTable
          clientSide
          tableName="error_upload_po_table"
          columns={columns}
          queryState={
            {
              data: error,
              isLoading: false,
            } as QueryState<ItemMasterUploadErrorV2>
          }
          isResizable
        />
      </Box>
      <Flex mt={4} gap={3}>
        <Button size="md" colorScheme="primaryBlue" onClick={onBack}>
          Back
        </Button>
        <Button size="md" colorScheme="primaryBlue" onClick={downloadErrors}>
          Download Errors
        </Button>
      </Flex>
    </>
  );
}
