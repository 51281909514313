import { useToast } from '@chakra-ui/react';
import { readFileAsText } from 'utils/fileUtils';
import { parse } from 'csv-parse/browser/esm/sync';
import { useState } from 'react';

type UseParseCsvOptions = {
  rowLimit?: number;
  onSuccess?: (file: File, data: any) => void;
};

export const useParseCsv = ({
  rowLimit = 100,
  onSuccess,
}: UseParseCsvOptions = {}) => {
  const toast = useToast();
  const [state, setState] = useState<{ file?: File; data: any }>({
    file: undefined,
    data: [],
  });

  const handleUpload = async (file?: File): Promise<void> => {
    if (!file) return;
    try {
      const data = parse(await readFileAsText(file), {
        columns: true,
        trim: true,
        relax_column_count: true,
        skip_empty_lines: true,
        from_line: 2,
        cast: (value) => (value === '' ? undefined : value),
      });

      let errorMessage = '';
      if (!Array.isArray(data) || !data.length) {
        errorMessage = 'No data found in file';
      } else if (data.length > rowLimit) {
        errorMessage = `Limit of ${rowLimit} rows per file`;
      }
      if (errorMessage) {
        toast({
          status: 'error',
          title: 'Error',
          description: errorMessage,
        });
        return;
      }
      setState({ file, data });
      onSuccess?.(file, data);
    } catch (err: any) {
      toast({
        status: 'error',
        title: 'Invalid CSV',
        description: err.message,
      });
    }
  };

  return { state, onChange: handleUpload };
};
