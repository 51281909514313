import { Button, Flex, Tag, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ItemMasterCustomsInfo } from 'types/itemMaster';
import { CONFIG } from 'utils/config';
import { AccordionItemMasters } from './AccordionItemMasters';

export type Props = {
  data: ItemMasterCustomsInfo[];
  message?: string;
};

export function SuccessUploadItemMasters({ data, message }: Props) {
  const navigate = useNavigate();

  return (
    <>
      <Text
        fontWeight={500}
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        gap={2}
      >
        <Tag
          as="span"
          size="lg"
          fontSize="lg"
          variant="solid"
          colorScheme="green"
        >
          Success
        </Tag>
        {message}
      </Text>
      <AccordionItemMasters data={data} />
      <Text fontWeight={500}>
        Made a mistake? You can submit a new file to update a SKU.
      </Text>
      <Text fontWeight={500} maxW="600px">
        To update dimensions, UPC, or scannable barcodes on a SKU, please submit
        a request to our help center. SKUs that have not been received yet can
        be updated. SKUs that have been received have these fields locked.
      </Text>
      <Flex mt={4} gap={3}>
        <Button
          size="md"
          variant="solid"
          colorScheme="primaryBlue"
          onClick={() => navigate('/item-masters')}
        >
          View Item Master
        </Button>
        <Button
          as="a"
          size="md"
          colorScheme="primaryBlue"
          target="_blank"
          href={CONFIG().helpCenterUrl}
        >
          Visit Help Center
        </Button>
      </Flex>
    </>
  );
}
