import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { ProcessedContainerDto } from 'types/purchaseOrders';
import { mapDtoToProcessedContainers } from './mappers';

export const useProcessedContainers = (poNumber?: string) => {
  return useQuery(
    ['processed-containers', poNumber],
    async () => {
      const { data } = await axios.get<{ items: ProcessedContainerDto[] }>(
        '/processed-containers',
        {
          params: {
            po_number: poNumber,
            limit: 500,
          },
        }
      );
      return data.items.map(mapDtoToProcessedContainers).sort((a, b) => {
        const dateA = new Date(a.carrierDeliveryAppointment ?? 0);
        const dateB = new Date(b.carrierDeliveryAppointment ?? 0);
        return dateB.getTime() - dateA.getTime();
      });
    },
    {
      enabled: Boolean(poNumber),
    }
  );
};
