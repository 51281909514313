import { useMutation } from '@tanstack/react-query';
import { useMsaFile } from 'queries/admin/useMsaFile';
import { FileType } from 'utils/fileTypes';
import { downloadReport } from 'utils/reportUtils';

export const useDownloadMsaFile = () => {
  const { getPdfFile } = useMsaFile();
  return useMutation({
    mutationFn: async (fileName: string) => {
      const data = await getPdfFile(fileName);
      downloadReport({ fileName, data, fileType: FileType.Pdf });
    },
  });
};
