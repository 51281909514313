/* istanbul ignore file */
import * as yup from 'yup';
import { CarrierAccount } from 'types/shipVia';
import { filterShipVias } from 'utils/shipViaUtils';

export const basicSalesOrderInfoSchema = yup
  .object({
    soNumber: yup.string().label('Shipment Number').required().max(50),
    poNumber: yup
      .string()
      .label('Reference Number')
      .max(150)
      .nullable()
      .optional(),
    dueDate: yup.string().label('Ship By Date').optional().nullable(),
    shipVia: yup
      .string()
      .label('Carrier')
      .required()
      .max(50)
      .test(
        'is-valid-ship-via',
        () => 'Ship via is not valid for this FC/Country',
        function (state = '', { options: { context } }) {
          // Only validate shipVia once country is set.
          if (!this.parent.shipTo.country) {
            return true;
          }

          const shipVias = filterShipVias(
            (context?.carrierAccounts ?? []) as CarrierAccount[],
            {
              carrierAccountUUID: this.parent.carrierAccountUUID,
              warehouseID: this.parent.warehouseID,
              country: this.parent.shipTo.country,
            }
          );
          return (
            !state || this.parent.type === 'Freight' || shipVias.length > 0
          );
        }
      ),
    warehouseID: yup.string().label('Fulfillment Center').required(),
    giftMessage: yup
      .string()
      .optional()
      .nullable()
      .max(500)
      .label('Gift Message'),
    storefrontName: yup
      .string()
      .label('Storefront')
      .nullable()
      .optional()
      .test(
        'is-required-storefront',
        () => 'Storefront is required',
        function (state, { options: { context } }) {
          const storefrontsCount = context?.storefronts?.length;
          return !storefrontsCount
            ? true
            : storefrontsCount > 0 && Boolean(state);
        }
      ),
  })
  .required();
