import { useTenant } from 'queries/tenant/useTenant';
import { useMemo } from 'react';

type UsePermissionProps = {
  enabled?: boolean;
  allowTenants: string[];
};

export const usePermission = ({
  enabled,
  allowTenants,
}: UsePermissionProps) => {
  const { data: tenant, isLoading } = useTenant({ enabled });

  const isAllowed = useMemo(
    () => allowTenants.includes(tenant?.code ?? ''),
    [tenant, allowTenants]
  );

  return { isAllowed, isLoading };
};
