import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Box } from '@chakra-ui/react';
import { BigLoading } from 'components/BigLoading';
import { useSearchParams } from 'react-router-dom';
import { AccessDenied } from './AccessDenied';
import { useAuth0Roles } from 'hooks/useAuth0Roles';
import { useMemo } from 'react';
import axios from 'axios';
import { usePermission } from 'hooks/usePermission';

interface ProtectedRouteProps {
  component: React.ComponentType<any>;
  requireOneOfRoles?: Array<string>;
  allowTenants?: Array<string>;
  allowRoles?: Array<string>;
}

export const ProtectedRoute = ({
  component,
  requireOneOfRoles,
  allowTenants,
  allowRoles,
}: ProtectedRouteProps) => {
  const [query] = useSearchParams();
  const invitation = query.get('invitation') ?? undefined;
  const organization = query.get('organization') ?? undefined;
  const error = query.get('error') ?? undefined;
  const hasOneOfRoles = useAuth0Roles(requireOneOfRoles);
  const hasAllowRoles = useAuth0Roles(allowRoles);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { isAllowed: isTenantAllowed, isLoading: isLoadingTenant } =
    usePermission({
      allowTenants: allowTenants ?? [],
      enabled: isAuthenticated,
    });

  useMemo(async () => {
    axios.interceptors.request.use(async (config) => {
      const accessToken = await getAccessTokenSilently();
      if (!config.headers) config.headers = {};
      config.headers['Authorization'] = `Bearer ${accessToken}`;
      return config;
    });
  }, [getAccessTokenSilently]);

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <Box>
        <BigLoading />
      </Box>
    ),
    claimCheck: (claims) => {
      const claimsOrgId = claims?.org_id ?? undefined;
      // return false if the Auth0 organization ID in the URL doesn't match the claim to trigger login
      if (organization != null && organization !== claimsOrgId) {
        return false;
      }
      return true;
    },
    loginOptions: {
      organization,
      invitation,
    },
  });

  if (!isAuthenticated) {
    return <Component />;
  } else if (isLoadingTenant) {
    return <BigLoading />;
  }

  const userAllowed =
    !requireOneOfRoles &&
    (isTenantAllowed || hasAllowRoles || (!allowTenants && !allowRoles));

  return error !== 'access_denied' &&
    ((!!requireOneOfRoles && hasOneOfRoles) || userAllowed) ? (
    <Component />
  ) : (
    <AccessDenied />
  );
};
