import {
  ItemMaster,
  ItemMasterDto,
  ItemMasterUploadError,
  ItemMasterUploadErrorResult,
} from 'types/itemMaster';
import { stringify } from 'utils/jsonUtils';

export function mapDtoToItemMaster(itemMaster: ItemMasterDto): ItemMaster {
  return {
    sku: itemMaster.sku,
    standvastSku: itemMaster.standvast_sku,
    description: itemMaster.description,
    unitOfMeasure: itemMaster.uom,
    length: itemMaster.length,
    width: itemMaster.width,
    height: itemMaster.height,
    weight: itemMaster.weight,
    cubicFeet: itemMaster.cuft,
    isSmallPack: itemMaster.is_small_pack,
    tariffNumber: itemMaster.harmonized_tariff_number,
    itemValue: itemMaster.item_value,
    itemValueCurrency: itemMaster.item_value_currency,
    unitOfMeasureWeight: itemMaster.weight_uom,
    conversionFactor: itemMaster.conversion_factor,
    upc: itemMaster.upc,
    requiresLotControl: itemMaster.is_lot_control,
    isConveyable: itemMaster.is_conveyable,
    hazmatUnNumber: itemMaster.hazmat_un_number,
    customsValue: itemMaster.customs_value,
    commodityDescription: itemMaster.commodity_description,
    countryOfManufacture: itemMaster.country_of_manufacture,
    customsWeight: itemMaster.customs_weight,
  };
}

export function mapItemMasterUploadErrorToResults(
  error: ItemMasterUploadError | undefined
): ItemMasterUploadErrorResult[] {
  if (Array.isArray(error)) {
    // make sure we display something for unruly validation errors
    return error.map((e) => ({ reason: stringify(e) })) ?? [];
  }
  if (Array.isArray(error?.itemmasters)) {
    return (
      error?.itemmasters.map((e) => ({
        reason: stringify(e),
      })) ?? []
    );
  }
  if (Array.isArray(error?.errors)) {
    return error?.errors.map((e) => ({ reason: e })) ?? [];
  }
  return error?.detail
    ? [{ reason: error.detail }]
    : [{ reason: stringify(error) }];
}
