import { endOfDay, parseISO, startOfDay, subDays } from 'date-fns';
import { formatInTimeZone, toZonedTime } from 'date-fns-tz';

export const ISO_FORMAT = "yyyy-MM-dd'T'HH:mm:ssX";

export function formatDate(
  date?: string,
  format:
    | 'MM/DD/YYYY'
    | 'YYYY-MM-DD'
    | 'MM/DD/YYYY HH:MM'
    | 'YYYY-MM-DD HH:MM' = 'MM/DD/YYYY',
  options?: Intl.DateTimeFormatOptions
) {
  if (format.endsWith('HH:MM')) {
    return date
      ? new Date(date).toLocaleString(
          format.startsWith('MM/DD/YYYY') ? 'en-US' : 'en-CA',
          options
        )
      : '';
  }

  return date
    ? new Date(date).toLocaleDateString(
        format === 'MM/DD/YYYY' ? 'en-US' : 'en-CA',
        options
      )
    : '';
}

export function formatTime(
  date?: string,
  format: 'HH:MM' | 'HH:MM AM/PM' | 'HH:MM:SS' | 'HH:MM:SS AM/PM' = 'HH:MM',
  options?: Intl.DateTimeFormatOptions
) {
  if (!date) return '';
  options = options || {};
  switch (format) {
    case 'HH:MM':
      return new Date(date).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        ...options,
      });
    case 'HH:MM AM/PM':
      return new Date(date).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
        ...options,
      });
    case 'HH:MM:SS':
      return new Date(date).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
        ...options,
      });
    case 'HH:MM:SS AM/PM':
      return new Date(date).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
        ...options,
      });
  }
}

export function formatDateTime(
  date?: string,
  dateFormat: 'MM/DD/YYYY' | 'YYYY-MM-DD' = 'MM/DD/YYYY',
  timeFormat: 'HH:MM' | 'HH:MM AM/PM' | 'HH:MM:SS' | 'HH:MM:SS AM/PM' = 'HH:MM',
  dateOptions?: Intl.DateTimeFormatOptions,
  timeOptions?: Intl.DateTimeFormatOptions
) {
  return date
    ? formatDate(date, dateFormat, dateOptions) +
        ' ' +
        formatTime(date, timeFormat, timeOptions)
    : '';
}

export function parseDate(date?: string) {
  if (!date) return;
  if (!/^\d{4}-\d{2}-\d{2}/.test(date)) return new Date(date);

  const [yyyy, mm, dd] = date.slice(0, 10).split('-');
  return new Date(+yyyy, +mm - 1, +dd);
}

//TODO: Remove this function
export function parseDateInUTC(date: string | Date): Date {
  if (date instanceof Date) {
    return date;
  } else {
    return toZonedTime(parseISO(date), 'UTC');
  }
}

export function toISOInUTC(date: Date): string {
  return formatInTimeZone(date, 'UTC', ISO_FORMAT);
}

export function getLastMonthDateRange(): { from: string; to: string } {
  return getLastXDaysRange(30);
}

export function getLastWeekDateRange(): { from: string; to: string } {
  return getLastXDaysRange(7);
}

function getLastXDaysRange(dayCount: number) {
  const today = new Date();

  return {
    from: subDays(startOfDay(today), dayCount).toISOString(),
    to: endOfDay(today).toISOString(),
  };
}
