import { Box, Button, Text, useToast, VStack } from '@chakra-ui/react';
import { UploadButton } from 'components/UploadButton';
import { useParseCsv } from 'hooks/useParseCsv';
import {
  UPLOAD_ITEM_MASTER_HELP_AND_EXAMPLE_URL,
  UPLOAD_ITEM_MASTER_TEMPLATE_URL,
} from 'constants/templateLinks';
import {
  ItemMasterUploadPreview,
  ItemMasterCustomsInfo,
} from 'types/itemMaster';
import { useItemMasterCustomsInfo } from 'mutations/itemMasters/useItemMasterCustomsInfo';
import {
  mapRowsToItemMasterUploadPreview,
  mapRowsToSkuList,
} from 'mutations/itemMasters/mappers';
import { useTenant } from 'queries/tenant/useTenant';

const ROW_LIMIT = 500;

type Props = {
  onChange: (
    file: File,
    data: ItemMasterUploadPreview[],
    customsInfo: ItemMasterCustomsInfo[]
  ) => void;
};

export function UploadItemMasters({ onChange }: Props) {
  const toast = useToast();
  const { data: tenant } = useTenant();
  const { mutateAsync: getCustomsInfo, isLoading } = useItemMasterCustomsInfo();
  const { onChange: handleUpload } = useParseCsv({
    rowLimit: ROW_LIMIT,
    onSuccess: async (file, data) => {
      const skuList = mapRowsToSkuList(data);
      if (skuList.length === 0) {
        toast({
          status: 'error',
          title: 'Error',
          description: 'No SKUs found in file',
        });
        return;
      }
      const customsInfo = await getCustomsInfo({
        tenantUuid: tenant?.uuid!,
        skuList,
      });
      onChange(file, mapRowsToItemMasterUploadPreview(data), customsInfo);
    },
  });

  return (
    <VStack align="start" spacing={3}>
      <Text as="h3" variant="pageHeading" my={1}>
        1. Download Template
      </Text>
      <Text fontWeight={500}>
        To create new SKUs, download the template and complete offline.
      </Text>
      <Button as="a" size="md" download href={UPLOAD_ITEM_MASTER_TEMPLATE_URL}>
        Download Template
      </Button>
      <Text fontWeight={500}>
        To update existing SKUs, download your existing item master and edit
        offline.
      </Text>
      <Button as="a" size="md" target="_blank" href="/item-masters">
        View Item Master
      </Button>
      <Text fontWeight={500}>
        Read help docs for explanation about all of the fields.
      </Text>
      <Button
        as="a"
        size="md"
        target="_blank"
        href={UPLOAD_ITEM_MASTER_HELP_AND_EXAMPLE_URL}
      >
        View Help and Examples
      </Button>
      <Text as="h3" variant="pageHeading" my={1}>
        2. Upload File
      </Text>
      <Box>
        <Text fontWeight={500}>
          Upload the file. Your input will be checked and you can preview before
          executing.
        </Text>
        <Text fontWeight={700}>Limit of {ROW_LIMIT} rows per file.</Text>
      </Box>
      <UploadButton
        slim
        data-testid="itemMastersUpload"
        accept=".csv"
        label="Upload"
        buttonProps={{
          size: 'md',
          variant: 'solid',
          colorScheme: 'primaryBlue',
          isLoading,
        }}
        onChange={handleUpload}
      />
    </VStack>
  );
}
