import { Button, Flex, Text, VStack } from '@chakra-ui/react';
import { PurchaseOrderPreview } from 'types/purchaseOrders';
import { AccordionPurchaseOrders } from './components/AccordionPurchaseOrders';

type Props = {
  isLoading?: boolean;
  data: PurchaseOrderPreview[];
  onBack: () => void;
  onConfirm: () => void;
};

export function PreviewPurchaseOrders({
  isLoading,
  data,
  onBack,
  onConfirm,
}: Props) {
  return (
    <VStack align="start" spacing={3}>
      <Text as="h3" variant="pageHeading" my={1}>
        3. Preview Changes
      </Text>
      <Text fontWeight={500}>
        {data.length} inbound shipping plan(s) will be uploaded. Confirm your
        changes before continuing.
      </Text>
      <AccordionPurchaseOrders data={data} />
      <Flex mt={4} gap={3}>
        <Button
          isDisabled={isLoading}
          size="md"
          colorScheme="primaryBlue"
          onClick={onBack}
        >
          Back
        </Button>
        <Button
          isLoading={isLoading}
          size="md"
          variant="solid"
          colorScheme="primaryBlue"
          onClick={onConfirm}
        >
          Confirm
        </Button>
      </Flex>
    </VStack>
  );
}
