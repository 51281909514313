import { PaginatedTable } from 'components/PaginatedTable';
import { useCallback, useEffect, useMemo } from 'react';
import { Row } from 'react-table';
import { QueryState } from 'types/queryState';
import {
  getQueryParamsObject,
  parseFilters,
  parseSort,
  querifyFilters,
  querifySort,
  QueryParamsObject,
} from 'utils/urlSearchParamsUtils';
import { ColumnMetaData, useGridFiltering } from 'hooks/useGridFiltering';
import { SortState, useGridSorting } from 'hooks/useGridSorting';
import { useLayoutPaginatedTable } from 'hooks/useLayoutPaginatedTable';
import { MsaDocument } from 'types/msa';
import { useSearchParams } from 'react-router-dom';
import { queryToMsaField, msaFieldToQuery } from './msaTableParams';
import { useStore } from 'contexts/storeContext';
import * as _ from 'lodash-es';
import { focusToField } from 'utils/browserUtils';
import { useMsaDocuments } from 'queries/admin/useMsaDocuments';
import { formatDate } from 'utils/dateUtils';
import { Box, Button, useDisclosure } from '@chakra-ui/react';
import { UploadMsaModal } from './upload-msa-modal/UploadMsaModal';
import { UploadMsaProvider } from 'hooks/useUploadMsa';
import { MsaLink } from './MsaLink';
import ClearIcon from '@mui/icons-material/Clear';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { useDeleteMsaDocument } from 'mutations/admin/useDeleteMsaDocument';
import { ActionButton } from 'components/ActionButton';
import { DownloadableButton } from './DownloadableButton';

type Props = {
  enableUpload?: boolean;
  enableDelete?: boolean;
};

function MsaTable({ enableUpload, enableDelete }: Props) {
  const [state, dispatch] = useStore();
  const [searchParams, setSearchParams] = useSearchParams({
    sort: '-effective-end-date',
  });
  const {
    isOpen: isOpenUploadMsaModal,
    onClose: onCloseUploadMsaModal,
    onOpen: onOpenUploadMsaModal,
  } = useDisclosure();

  const { getColumnWidth } = useLayoutPaginatedTable(800);
  const { openConfirmationDialog } = useConfirmationDialog();

  const queryResult = useMsaDocuments({ refetchOnMount: false });
  const { mutateAsync: deleteMsaDocument, isLoading: isDeleting } =
    useDeleteMsaDocument();

  const isLoading =
    queryResult.isLoading || queryResult.isFetching || isDeleting;

  const columnsMetaData: { [key: string]: ColumnMetaData } = {
    agreementTitle: { dataType: 'text' },
    effectiveStartDate: { dataType: 'date-range', useUTC: true },
    effectiveEndDate: { dataType: 'date-range', useUTC: true },
    delete: {
      dataType: 'text',
      isSortable: false,
      isFilterable: false,
      hideSettingsButton: true,
    },
  };

  const handleDelete = useCallback(
    (fileName: string) => {
      openConfirmationDialog({
        title: 'Delete',
        message: 'Are you sure you want to delete this MSA?',
        onConfirm: () => deleteMsaDocument(fileName),
        blockScrollOnMount: false,
        confirmButtonLabel: 'Yes',
      });
    },
    [deleteMsaDocument, openConfirmationDialog]
  );

  const updateSearchParams = useCallback(
    (updating: object) => {
      setSearchParams({
        ...searchParams,
        ...updating,
      });
    },
    [searchParams, setSearchParams]
  );

  const onFilterChange = useCallback(
    (filters: QueryParamsObject) => {
      dispatch(_.pick(filters, ['fieldToFocus']));
      updateSearchParams(
        querifyFilters(_.omit(filters, ['fieldToFocus']), msaFieldToQuery)
      );
    },
    [dispatch, updateSearchParams]
  );

  const onSortChange = useCallback(
    (sorting: SortState<MsaDocument>) => {
      updateSearchParams({ sort: querifySort(sorting, msaFieldToQuery) });
    },
    [updateSearchParams]
  );

  const gridFiltering = useGridFiltering<MsaDocument>({
    columnsMetaData,
    initialFilters: {
      ...parseFilters(getQueryParamsObject(searchParams), queryToMsaField),
      tab: searchParams.get('tab'),
    },
    onFilterChange,
  });

  const gridSorting = useGridSorting<MsaDocument>({
    getQueryState: () => queryResult,
    initialSort: parseSort(searchParams.get('sort') || '', queryToMsaField),
    onSortChange,
  });

  const columns = useMemo(
    () => [
      {
        Header: 'Agreement Title',
        accessor: 'fileName',
        helpText: 'Title of the MSA agreement PDF document',
        width: getColumnWidth(enableDelete ? 40 : 45, 300),
        Cell: ({ value }: { value: string }) => <MsaLink fileName={value} />,
      },
      {
        Header: 'Effective Start Date',
        accessor: 'effectiveStartDate',
        width: getColumnWidth(25, 200),
        helpText: 'The date the MSA comes into effect',
        Cell: ({ value }: { value: string }) => (
          <>{formatDate(value, 'MM/DD/YYYY', { timeZone: 'UTC' })}</>
        ),
      },
      {
        Header: 'Effective End Date',
        accessor: 'effectiveEndDate',
        width: getColumnWidth(25, 200),
        helpText: 'The date the MSA’s effectiveness ends',
        Cell: ({ value }: { value: string }) => (
          <>{formatDate(value, 'MM/DD/YYYY', { timeZone: 'UTC' })}</>
        ),
      },
      {
        accessor: 'delete',
        width: getColumnWidth(enableDelete ? 10 : 5, 100),
        Cell: ({ row }: { row: Row<MsaDocument> }) => (
          <Box display="flex" justifyContent="center">
            <DownloadableButton fileName={row.original.fileName} />
            {enableDelete ? (
              <ActionButton
                ml={2}
                aria-label="Delete Button"
                onClick={() => handleDelete(row.original.fileName)}
              >
                <ClearIcon fontSize="inherit" />
              </ActionButton>
            ) : null}
          </Box>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getColumnWidth, enableDelete]
  );

  useEffect(() => {
    if (state && state.fieldToFocus) {
      focusToField(state.fieldToFocus);
    }
  }, [state]);

  return (
    <Box display="flex" flexDir="column" mt={4}>
      {enableUpload ? (
        <Button
          isLoading={isLoading}
          onClick={onOpenUploadMsaModal}
          fontSize="md"
          variant="redSquareButton"
          alignSelf="flex-end"
          rounded="md"
        >
          Upload MSA
        </Button>
      ) : null}
      <PaginatedTable
        clientSide
        tableName="admin_msas_table"
        gridFiltering={gridFiltering}
        gridSorting={gridSorting}
        columns={columns}
        queryState={
          {
            ...queryResult,
            data: isLoading ? [] : queryResult.data || [],
            isLoading,
          } as QueryState<MsaDocument>
        }
        isResizable
      />
      {enableUpload ? (
        <UploadMsaProvider value={{ msaDocuments: queryResult.data }}>
          <UploadMsaModal
            isOpen={isOpenUploadMsaModal}
            onClose={onCloseUploadMsaModal}
          />
        </UploadMsaProvider>
      ) : null}
    </Box>
  );
}

export default MsaTable;
