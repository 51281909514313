import { useFormContext } from 'react-hook-form';
import ShipOptionSelect from 'features/create-order/ShipOptionSelect';
import { ShipViaSelect } from 'features/create-order/ShipViaSelect';
import { RATE_SHOP_OPTION } from 'queries/shipVias/useCarrierAccounts';

type Props = {
  startTabIndex: number;
};

export const ShipViaForm: React.FC<Props> = ({ startTabIndex }) => {
  const { watch } = useFormContext();
  const shipViaValue = watch('shipVia');

  return (
    <>
      <ShipViaSelect tabIndex={startTabIndex + 1} />
      {shipViaValue === RATE_SHOP_OPTION && (
        <ShipOptionSelect tabIndex={startTabIndex + 2} />
      )}
    </>
  );
};
