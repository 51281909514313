import { Button, GridItem, SimpleGrid, Text } from '@chakra-ui/react';

import { ShipToForm } from 'features/order-details/components/ModifyOrder/ShipToForm';
import { FormInput } from 'components/FormInput';
import { useFormContext } from 'react-hook-form';
import { StorefrontSelect } from './StorefrontSelect';
import { isReshipOrder } from 'utils/salesOrdersUtils';

type Props = {
  onGoToNext: () => void;
};

export function DestinationForm({ onGoToNext }: Props) {
  const { register, watch } = useFormContext();
  const soNumber = watch('soNumber');

  return (
    <SimpleGrid columns={1} gap={6}>
      <SimpleGrid columns={{ base: 1, md: 2 }} columnGap={8}>
        <GridItem colSpan={{ base: 1, md: 2 }}>
          <Text as="h3" variant="pageHeading">
            Identifiers
          </Text>
        </GridItem>
        <FormInput
          tabIndex={1}
          isRequired
          isReadOnly={Boolean(isReshipOrder(soNumber))}
          variant="outline"
          label="Shipment Number"
          tooltip="The order number from your system."
          {...register('soNumber')}
        />
        <FormInput
          tabIndex={2}
          variant="outline"
          label="Reference Number"
          tooltip="Optional. A secondary reference number for this order."
          {...register('poNumber')}
        />
        <StorefrontSelect
          variant="outline"
          gridColumn={{ base: 1, md: '1 / span 2' }}
          tabIndex={3}
        />
      </SimpleGrid>
      <GridItem>
        <Text as="h3" variant="pageHeading">
          Destination
        </Text>
        <ShipToForm variant="outline" startTabIndex={3} />
      </GridItem>
      <GridItem>
        <Button
          tabIndex={13}
          variant="solid"
          colorScheme="primaryBlue"
          size="md"
          onClick={onGoToNext}
        >
          Next
        </Button>
      </GridItem>
    </SimpleGrid>
  );
}
