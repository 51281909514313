import { ComponentStyleConfig } from '@chakra-ui/react';

export const formLabelTheme: ComponentStyleConfig = {
  baseStyle: {
    mt: 6,
    color: 'primaryBlue.500',
  },
  variants: {
    medium: {
      fontSize: 'md',
      fontWeight: 'bold',
    },
  },
  defaultProps: {
    variant: 'medium',
  },
};
