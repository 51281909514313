/* istanbul ignore file */
import { ResponsiveValue, SimpleGrid } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { FormInput } from 'components/FormInput';
import { SalesOrder } from 'types/salesOrders';
import { FormSwitch } from 'components/FormSwitch';

type Props = {
  startTabIndex: number;
  variant?: ResponsiveValue<string>;
};

export const FreightInfoForm: React.FC<Props> = ({
  variant = 'flushed',
  startTabIndex,
}) => {
  const { register } = useFormContext<SalesOrder>();
  return (
    <SimpleGrid aria-label="Freight Labels" columns={[1, 1, 2]} columnGap={8}>
      <FormInput
        variant={variant}
        tabIndex={startTabIndex + 1}
        label="Carrier SCAC Code"
        {...register('carrierScacCode')}
      />
      <SimpleGrid columns={2} gap={3}>
        <FormSwitch
          tabIndex={startTabIndex + 2}
          label="GTIN 14 Label?"
          {...register('printGtin14Label')}
        />
        <FormSwitch
          tabIndex={startTabIndex + 3}
          label="UPC Label?"
          {...register('printUpcLabel')}
        />
      </SimpleGrid>
      <FormInput
        variant={variant}
        tabIndex={startTabIndex + 4}
        label="ARN"
        {...register('arn')}
      />
      <FormInput
        variant={variant}
        tabIndex={startTabIndex + 5}
        label="ASN"
        {...register('asn')}
      />
    </SimpleGrid>
  );
};
