import {
  Alert,
  AlertIcon,
  Button,
  Collapse,
  FormLabel,
  GridItem,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import AsteriskIcon from 'components/AsteriskIcon';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { SalesOrder, SalesOrderType } from 'types/salesOrders';
import { WarehouseSelect } from './WarehouseSelect';
import { OrderLines } from 'features/order-lines/OrderLines';
import { GiftMessageForm } from './GiftMessageForm';
import { FreightInfoForm } from './FreightInfoForm';

type Props = {
  onGoToPrevious: () => void;
  onGoToNext: () => void;
};

type OrderType = {
  label: string;
  value: SalesOrderType;
};

const START_TAB_INDEX_ORDER_LINES = 1;
const TAB_INDEX_PER_LINE = 6;

export function LineItemsForm({ onGoToPrevious, onGoToNext }: Props) {
  const { watch, setValue } = useFormContext<SalesOrder>();

  const type = watch('type');
  const lines = watch('lines');

  const orderTypes: OrderType[] = useMemo(() => {
    return [
      {
        label: 'Parcel (Direct to Consumer)',
        value: 'Parcel',
      },
      {
        label: 'Cases/Freight (Business to Business)',
        value: 'Freight',
      },
    ];
  }, []);

  const tabIndexAfterOrderLines = useMemo(() => {
    return lines.length * TAB_INDEX_PER_LINE + START_TAB_INDEX_ORDER_LINES;
  }, [lines]);

  return (
    <SimpleGrid columns={1} gap={{ base: 4, md: 6 }}>
      <Text as="h3" variant="pageHeading">
        Shipment Type
      </Text>
      <GridItem>
        <FormLabel display="flex" mt={0} mb={2}>
          Processing Type
          <AsteriskIcon mb={-1} width="0.375rem" />
        </FormLabel>
        <RadioGroup
          value={type}
          onChange={(value) => setValue('type', value as SalesOrderType)}
        >
          <Stack
            spacing={{ base: 3, md: 6 }}
            direction={{ base: 'column', md: 'row' }}
            flexWrap="wrap"
          >
            {orderTypes.map((item) => (
              <Radio key={item.value} value={item.value}>
                {item.label}
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
        <WarehouseSelect tabIndex={1} variant="outline" orderType={type} />
      </GridItem>
      <GridItem>
        <Text as="h3" variant="pageHeading">
          Line Items
        </Text>
        <Collapse in={type === 'Freight'} animateOpacity unmountOnExit>
          <Alert status="info" mt={4}>
            <AlertIcon />
            Specify quantities in eaches. Quantities should be in multiples of
            quantities per case, otherwise VAS will be required.
          </Alert>
        </Collapse>
        <OrderLines
          mt={-3}
          startTabIndex={START_TAB_INDEX_ORDER_LINES}
          isFreightOrder={type === 'Freight'}
        />
      </GridItem>
      <Collapse in={type === 'Freight'} animateOpacity unmountOnExit>
        <GridItem>
          <Text as="h3" variant="pageHeading">
            Freight Labels
          </Text>
          <FreightInfoForm
            variant="outline"
            startTabIndex={tabIndexAfterOrderLines + 1}
          />
        </GridItem>
      </Collapse>
      <Collapse in={type === 'Parcel'} animateOpacity unmountOnExit>
        <GridItem>
          <Text as="h3" variant="pageHeading">
            Special Instructions
          </Text>
          <GiftMessageForm tabIndex={tabIndexAfterOrderLines + 7} />
        </GridItem>
      </Collapse>
      <GridItem>
        <Button
          tabIndex={tabIndexAfterOrderLines + 9}
          colorScheme="primaryBlue"
          size="md"
          onClick={onGoToPrevious}
          mr={3}
        >
          Back
        </Button>
        <Button
          tabIndex={tabIndexAfterOrderLines + 10}
          variant="solid"
          colorScheme="primaryBlue"
          size="md"
          onClick={onGoToNext}
        >
          Next
        </Button>
      </GridItem>
    </SimpleGrid>
  );
}
