import { CreateNetworkOrder, CreateNetworkOrderDto } from 'types/networkOrder';
import { omitEmptyFields } from 'utils/objectUtils';

export function mapNetworkOrderToDto(
  order: CreateNetworkOrder
): CreateNetworkOrderDto {
  return omitEmptyFields({
    tenant_username: order.tenantUsername,
    request_transaction_id: order.requestTransactionId,
    name: order.name,
    po_number: order.poNumber,
    ship_via: order.shipVia,
    ship_option: order.shipOption,
    target_dc: order.warehouseID,
    carrier_account_uuid: order.carrierAccountUUID,
    due_date: order.dueDate,
    desired_delivery_date: order.desiredDeliveryDate,
    earliest_due_date: order.earliestDueDate,
    gift_message: order.giftMessage,
    order_source: order.orderSource,
    customer_order_reference: order.customerOrderReference,
    address: omitEmptyFields({
      name: order.shipTo.name,
      city: order.shipTo.city,
      state: order.shipTo.state,
      postal_code: order.shipTo.zipCode,
      country: order.shipTo.country,
      address_1: order.shipTo.address,
      address_2: order.shipTo.address2,
      address_3: order.shipTo.address3,
      email: order.shipTo.email,
      phone_number: order.shipTo.phoneNumber,
    }),
    line_items: order.lines.map((item) =>
      omitEmptyFields({
        line_number: parseInt(item.lineNo),
        sku: item.sku,
        qty: item.orderQty,
        external_item_number: item.customerItemNumber,
      })
    ),
  });
}
