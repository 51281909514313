import {
  Box,
  Button,
  FormLabel,
  GridItem,
  Link,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import AsteriskIcon from 'components/AsteriskIcon';
import { useCarrierAccounts } from 'queries/shipVias/useCarrierAccounts';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { SalesOrder } from 'types/salesOrders';
import ShipOptionSelect from './ShipOptionSelect';
import { ShipViaSelect } from './ShipViaSelect';
import CarrierAccountSelect from './CarrierAccountSelect';
import { FormDatePicker } from 'components/FormDatePicker';
import { parseDate } from 'utils/dateUtils';
import { RATE_SHOP_OPTION } from 'queries/shipVias/useCarrierAccounts';
import { AlertMessage } from 'components/AlertMessage';

export const OUTBOUND_ORDER_REQUEST_URL =
  'https://standvast-team.atlassian.net/servicedesk/customer/portal/11/group/15/create/10173';

enum ShippingCategory {
  RateShop = 'RATE_SHOP',
  Manual = 'MANUAL',
}

type Props = {
  isSubmitting: boolean;
  onGoToPrevious: () => void;
  onSubmit: () => void;
};

export function ShippingAndBillingForm({
  isSubmitting,
  onSubmit,
  onGoToPrevious,
}: Props) {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<SalesOrder>();
  const { getShipVias } = useCarrierAccounts();

  const shipVia = watch('shipVia');
  const carrierAccountUUID = watch('carrierAccountUUID');
  const warehouseID = watch('warehouseID');
  const country = watch('shipTo.country');
  const type = watch('type');
  const dueDate = watch('dueDate');

  const shipVias = useMemo(
    () =>
      getShipVias({
        carrierAccountUUID,
        warehouseID,
        country,
      }),
    [getShipVias, carrierAccountUUID, warehouseID, country]
  );

  const [shippingCategory, setShippingCategory] = useState<ShippingCategory>(
    shipVia === RATE_SHOP_OPTION
      ? ShippingCategory.RateShop
      : ShippingCategory.Manual
  );

  const hasOnlyRateShop = useMemo(() => {
    return shipVias?.length === 1 && shipVias[0] === RATE_SHOP_OPTION;
  }, [shipVias]);

  const hasOnlyManual = useMemo(() => {
    return shipVias?.length
      ? !shipVias?.some((item) => item === RATE_SHOP_OPTION)
      : false;
  }, [shipVias]);

  const shippingCategories = useMemo(() => {
    return [
      {
        label: 'Rate Shop',
        isDisabled: !shipVias?.length || hasOnlyManual,
        value: ShippingCategory.RateShop,
        info: 'Standvast will choose the cheapest carrier shipping method among our carriers.',
      },
      {
        label: 'Manual',
        isDisabled: !shipVias?.length || hasOnlyRateShop,
        value: ShippingCategory.Manual,
        info: 'You choose the carrier shipping method directly.',
      },
    ];
  }, [hasOnlyRateShop, hasOnlyManual, shipVias]);

  const handleChangeShippingCategory = (value: ShippingCategory) => {
    setShippingCategory(value);
    setValue(
      'shipVia',
      value === ShippingCategory.RateShop ? RATE_SHOP_OPTION : '',
      {
        shouldValidate: true,
      }
    );
    if (value === ShippingCategory.Manual) {
      setValue('shipOption', '', { shouldValidate: true });
    }
  };

  useEffect(() => {
    if (!hasOnlyManual && !hasOnlyRateShop) return;

    if (hasOnlyManual) {
      setShippingCategory(ShippingCategory.Manual);
    } else {
      setShippingCategory(ShippingCategory.RateShop);
    }
  }, [hasOnlyManual, hasOnlyRateShop]);

  return (
    <SimpleGrid columns={1} gap={{ base: 4, md: 6 }}>
      {type === 'Parcel' && (
        <GridItem>
          <Text as="h3" variant="pageHeading">
            Billing Method
          </Text>
          <CarrierAccountSelect variant="outline" tabIndex={7} />
          {/* TODO: Add shipping account number when API is ready */}
          {/* <Text mt={2}>Shipping Account Number is</Text> */}
        </GridItem>
      )}
      <Text as="h3" variant="pageHeading">
        Shipping Method
      </Text>
      {type === 'Parcel' ? (
        <SimpleGrid columns={{ base: 1, md: 2 }} columnGap={8} rowGap={6}>
          <GridItem>
            <FormLabel display="flex" mt={0} mb={2}>
              Shipping Category
              <AsteriskIcon mb={-1} width="0.375rem" />
            </FormLabel>
            <RadioGroup
              value={shippingCategory}
              onChange={handleChangeShippingCategory}
            >
              <Stack spacing={3} direction="column">
                {shippingCategories.map((item, index) => (
                  <Box key={item.value}>
                    <Radio
                      tabIndex={1 + index}
                      value={item.value}
                      isDisabled={item.isDisabled}
                    >
                      {item.label}
                    </Radio>
                    <Text mt={2}>{item.info}</Text>
                  </Box>
                ))}
              </Stack>
            </RadioGroup>
          </GridItem>
          <GridItem mt={-6}>
            {shippingCategory !== ShippingCategory.RateShop && (
              <ShipViaSelect
                includeRateShopOption={false}
                variant="outline"
                tabIndex={3}
              />
            )}
            {shippingCategory === ShippingCategory.RateShop && (
              <ShipOptionSelect variant="outline" tabIndex={4} />
            )}
          </GridItem>
        </SimpleGrid>
      ) : (
        <GridItem mt={-6}>
          <FormDatePicker
            disabled
            tabIndex={1}
            label="Ship By Date"
            aria-label="Earliest Ship Date"
            isClearable
            variant="outline"
            defaultValue={parseDate(dueDate)}
            onChange={(value) =>
              setValue('dueDate', value, {
                shouldDirty: true,
                shouldValidate: true,
              })
            }
            error={errors.dueDate?.message}
          />
          <AlertMessage type="info" title="" mt={3}>
            Communicate your requested ship by date on the{' '}
            <Link
              isExternal
              style={{ textDecoration: 'underline' }}
              href={OUTBOUND_ORDER_REQUEST_URL}
            >
              Outbound Order Request
            </Link>{' '}
            form.
          </AlertMessage>
        </GridItem>
      )}
      <GridItem>
        <Button
          tabIndex={8}
          colorScheme="primaryBlue"
          size="md"
          onClick={onGoToPrevious}
          mr={3}
        >
          Back
        </Button>
        <Button
          tabIndex={9}
          isLoading={isSubmitting}
          variant="solid"
          colorScheme="primaryBlue"
          size="md"
          onClick={onSubmit}
        >
          Create
        </Button>
      </GridItem>
    </SimpleGrid>
  );
}
