import { ActionButton } from 'components/ActionButton';
import { useDownloadMsaFile } from 'mutations/admin/useDownloadMsaFile';
import DownloadIcon from '@mui/icons-material/Download';

type Props = {
  fileName: string;
};

export function DownloadableButton({ fileName }: Props) {
  const { mutateAsync: downloadMsaFile, isLoading: isDownloading } =
    useDownloadMsaFile();

  return (
    <ActionButton
      aria-label="Download Button"
      isLoading={isDownloading}
      onClick={() => downloadMsaFile(fileName)}
    >
      <DownloadIcon fontSize="inherit" />
    </ActionButton>
  );
}
