import { Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';
import { Modal } from 'components/Modal';
import { SortState } from 'hooks/useGridSorting';
import { useToast } from 'hooks/useToast';
import { useItemMastersExport } from 'mutations/itemMasters/useItemMastersExport';
import { useState } from 'react';
import { ItemMaster } from 'types/itemMaster';

enum ExportType {
  ExportAsShown = '1',
  ExportForUpdate = '2',
}

export type ExportItemMasterModalProps = {
  isOpen: boolean;
  onClose: () => void;
  sort: SortState<ItemMaster>;
  filters: { [columnId: string]: string };
};

export function ExportItemMasterModal({
  onClose,
  isOpen,
  filters,
  sort,
}: ExportItemMasterModalProps) {
  const toast = useToast();
  const [value, setValue] = useState(ExportType.ExportAsShown);
  const { mutateAsync: exportItemMasters, isLoading } = useItemMastersExport();

  const handleExport = async () => {
    try {
      await exportItemMasters({
        filters,
        sort,
        update: value === ExportType.ExportForUpdate,
      });
    } catch (error: any) {
      toast({
        title: 'Error',
        status: 'error',
        description: error?.message,
      });
    }
  };

  return (
    <Modal
      title={'Export Item Master'}
      isOpen={isOpen}
      size={['full', null, '2xl']}
      onClose={onClose}
      buttons={[
        {
          label: 'Export',
          variant: 'cta',
          buttonProps: {
            minW: 200,
          },
          isLoading,
          handler: handleExport,
        },
      ]}
    >
      <Text variant="pageHeading" mb={5}>
        Choose Type of Export
      </Text>
      <RadioGroup onChange={(val) => setValue(val as ExportType)} value={value}>
        <Stack>
          <Radio value={ExportType.ExportAsShown}>Export as Shown</Radio>
          <Text>Export the rows and columns as shown in the UX</Text>
          <Radio value={ExportType.ExportForUpdate}>Export for Update</Radio>
          <Text>
            Export the rows shown in appropriate format for editing and updating
            back into Standvast
          </Text>
        </Stack>
      </RadioGroup>
    </Modal>
  );
}
