import { CreateNetworkOrder } from 'features/create-network-order/CreateNetworkOrder';
import { usePortal } from 'hooks/usePortal';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { BigLoading } from 'components/BigLoading';
import { useCarrierAccounts } from 'queries/shipVias/useCarrierAccounts';

export function CreateNetworkOrderPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const urlSegments = location.pathname.split('/');
  const firstUrlSegment = urlSegments[1] ?? '';
  const { isLoading } = useCarrierAccounts();

  const { isPortalReadOnly } = usePortal();

  const handleClose = () => {
    navigate(`/${firstUrlSegment}${location.search ?? ''}`);
  };

  useEffect(() => {
    isPortalReadOnly && handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPortalReadOnly]);

  return !isLoading ? (
    <CreateNetworkOrder onAbort={handleClose} onConfirm={handleClose} />
  ) : (
    <BigLoading />
  );
}
