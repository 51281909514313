import 'react-datepicker/dist/react-datepicker.css';
import {
  forwardRef,
  MouseEventHandler,
  Ref,
  useId,
  useMemo,
  useState,
} from 'react';
import { Input, InputProps, ResponsiveValue } from '@chakra-ui/react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { startOfDay, endOfDay, parseISO } from 'date-fns';
import { format, FormatOptionsWithTZ, toZonedTime } from 'date-fns-tz';
import { ISO_FORMAT } from 'utils/dateUtils';

export interface DateRangePickerProps
  extends Omit<ReactDatePickerProps, 'selected' | 'onChange' | 'defaultValue'> {
  defaultValue?: string;
  onChange?: (value: string) => void;
  onMouseEnter?: MouseEventHandler;
  variant?: ResponsiveValue<string>;
  size?: 'xs' | 'sm' | 'md';
  'aria-label'?: string;
  useUTC?: boolean;
}

export function DateRangePicker({
  variant = 'outline',
  size = 'md',
  defaultValue,
  onChange,
  onMouseEnter,
  isClearable,
  'aria-label': ariaLabel,
  dateFormat = 'MM/dd/yy',
  useUTC = false,
  ...props
}: DateRangePickerProps) {
  const id = useId();

  const parsedDefaultRange: [Date | null, Date | null] = useMemo(() => {
    const defaultRange = defaultValue?.split('_') ?? [null, null];
    let parsedFromDate = defaultRange[0] ? parseISO(defaultRange[0]) : null;
    let parsedToDate = defaultRange[1] ? parseISO(defaultRange[1]) : null;
    if (useUTC) {
      if (parsedFromDate) {
        parsedFromDate = toZonedTime(parsedFromDate, 'UTC');
      }
      if (parsedToDate) {
        parsedToDate = toZonedTime(parsedToDate, 'UTC');
      }
    }
    return [parsedFromDate, parsedToDate];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [dateRange, setDateRange] =
    useState<[Date | null, Date | null]>(parsedDefaultRange);
  const [startDate, endDate] = dateRange;

  const handleChange = (date: [Date | null, Date | null]) => {
    setDateRange(date);
    if (onChange) {
      const timezoneOptions: FormatOptionsWithTZ = useUTC
        ? { timeZone: 'UTC' }
        : {};
      const fromISO = date[0]
        ? format(startOfDay(date[0]), ISO_FORMAT, timezoneOptions)
        : '';
      const toISO = date[1]
        ? format(endOfDay(date[1]), ISO_FORMAT, timezoneOptions)
        : '';

      if ((fromISO && toISO) || (!fromISO && !toISO)) {
        onChange(fromISO + (toISO ? `_${toISO}` : ''));
      }
    }
  };

  return (
    <DatePicker
      {...props}
      id={id}
      selectsRange
      selected={startDate}
      startDate={startDate}
      endDate={endDate}
      dateFormat={dateFormat}
      onChange={handleChange}
      customInput={
        <AdapterInput
          aria-label={ariaLabel}
          type={isClearable ? 'search' : 'text'}
          size={size}
          variant={variant}
          onMouseEnter={onMouseEnter}
        />
      }
    />
  );
}

const AdapterInput = forwardRef(
  (props: InputProps, ref: Ref<HTMLInputElement>) => (
    <Input ref={ref} {...props}></Input>
  )
);
