import React from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
  TextareaProps,
  Tooltip,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import get from 'lodash-es/get';
import HelpIcon from '@mui/icons-material/Help';

export type FormTextareaProps = TextareaProps & {
  label?: string;
  placeholder?: string;
  error?: string;
  hideLabel?: boolean;
  tooltip?: string;
};

export const FormTextarea = React.forwardRef<
  HTMLTextAreaElement,
  FormTextareaProps
>(({ label, placeholder, error, hideLabel, tooltip, ...props }, ref) => {
  const {
    formState: { errors: formErrors },
  } = useFormContext();
  const errorMessage =
    error ?? (get(formErrors, props.name ?? '')?.message as string);

  return (
    <FormControl variant="floating" isInvalid={!!errorMessage}>
      {label && !hideLabel && (
        <FormLabel display="flex" position="relative" zIndex={1}>
          {label}
          {tooltip ? (
            <Tooltip label={tooltip}>
              <HelpIcon
                fontSize="inherit"
                sx={{
                  marginLeft: '0.3rem',
                  width: '1.3rem',
                  height: '1.3rem',
                }}
              />
            </Tooltip>
          ) : null}
        </FormLabel>
      )}
      <Textarea
        aria-label={label}
        placeholder={placeholder}
        {...props}
        ref={ref}
      />
      {errorMessage && (
        <FormErrorMessage mb={2}>{errorMessage}</FormErrorMessage>
      )}
    </FormControl>
  );
});
