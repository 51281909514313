import { GridItem } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { parseDate } from 'utils/dateUtils';
import { SalesOrder } from 'types/salesOrders';
import { FormDatePicker } from 'components/FormDatePicker';

type Props = {
  startTabIndex: number;
};

export const DatesForm: React.FC<Props> = ({ startTabIndex }) => {
  const {
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<SalesOrder>();

  const dueDateValue = watch('dueDate');

  return (
    <GridItem>
      <FormDatePicker
        disabled
        tabIndex={startTabIndex + 1}
        label="Ship By Date"
        aria-label="Ship By Date"
        placeholder="Please select a date"
        isClearable
        preventOpenOnFocus
        variant="flushed"
        defaultValue={parseDate(dueDateValue)}
        onChange={(value) =>
          setValue('dueDate', value, {
            shouldDirty: true,
            shouldValidate: true,
          })
        }
        error={errors.dueDate?.message}
      />
    </GridItem>
  );
};
