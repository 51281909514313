import { Box, Text, useSteps } from '@chakra-ui/react';
import { PreviewItemMasters } from 'features/upload-item-masters/PreviewItemMasters';
import { UploadItemMasters } from 'features/upload-item-masters/UploadItemMasters';
import { ReviewItemMasters } from 'features/upload-item-masters/ReviewItemMasters';
import { useUploadItemMasters } from 'mutations/itemMasters/useUploadItemMasters';
import { useState } from 'react';
import {
  ItemMasterCustomsInfo,
  ItemMasterUploadPreview,
} from 'types/itemMaster';

export function UploadItemMastersPageV2() {
  const [file, setFile] = useState<File>();
  const [previewData, setPreviewData] = useState<ItemMasterUploadPreview[]>([]);
  const [customsInfo, setCustomsInfo] = useState<ItemMasterCustomsInfo[]>([]);
  const { activeStep, setActiveStep, goToNext, goToPrevious } = useSteps({
    index: 1,
    count: 3,
  });
  const { mutate, data, error, isLoading } = useUploadItemMasters();

  const uploadChange = (
    file: File,
    data: ItemMasterUploadPreview[],
    customsInfo: ItemMasterCustomsInfo[]
  ) => {
    setFile(file);
    setPreviewData(data);
    setCustomsInfo(customsInfo);
    goToNext();
  };

  const uploadItemMaster = () =>
    mutate(
      { file: file!, data: previewData },
      {
        onSettled: () => goToNext(),
      }
    );

  return (
    <Box>
      <Text as="h2" variant="pageTitle" mb={3}>
        Upload Item Master
      </Text>
      {activeStep === 1 && <UploadItemMasters onChange={uploadChange} />}
      {activeStep === 2 && (
        <PreviewItemMasters
          isLoading={isLoading}
          data={customsInfo}
          onBack={goToPrevious}
          onConfirm={uploadItemMaster}
        />
      )}
      {activeStep === 3 && (
        <ReviewItemMasters
          message={data?.message}
          data={customsInfo}
          error={error}
          onBack={() => setActiveStep(1)}
        />
      )}
    </Box>
  );
}
