export const PARCEL_SALES_ORDER_URL =
  'https://docs.google.com/spreadsheets/d/1WtaXkZH4mtqta5dqzwAH1Vi1Xe2GOsbq2Gt1aUZRTdI/edit?gid=1706883823#gid=1706883823';
export const FREIGHT_SALES_ORDER_URL =
  'https://docs.google.com/spreadsheets/d/11FCs7Rg038bo4e2Xw7shokRkq2V5k1UHpc-MWnqd7Qo/edit?gid=839088610#gid=839088610';
export const PURCHASE_ORDER_URL =
  'https://docs.google.com/spreadsheets/d/1X06unc9aSnh95wU6GcgXF-yTGwQr5PnNOKbPgVle6Y4/edit?gid=1650881913#gid=1650881913';
export const FORECAST_URL =
  'https://docs.google.com/spreadsheets/d/14pm_laOi2HEYZJvRWVJzpbJhBKkDKF0IuWcbqg77fPI/edit?gid=1706994989#gid=1706994989';
export const SHIPPING_ACCOUNT_SETUP_URL =
  'https://docs.google.com/spreadsheets/d/1oEjRiNX2T4TxcXOkT-hftOIiP00YEezdxpScgziwpaA/edit?gid=259283355#gid=259283355';
export const CREATE_ITEM_MASTER_URL =
  'https://docs.google.com/spreadsheets/d/1sjRQS0XhuC2oWKtdFiq-xDtuq1xV_IHbbIIztTYIYiw/edit?gid=28620531#gid=28620531';
export const UPDATE_ITEM_MASTER_URL =
  'https://docs.google.com/spreadsheets/d/1S06eTN2Ic_wKpmrUChe6-TWPv-f0tOjLwa7zhKZL1Kw/edit?gid=28620531#gid=28620531';
export const SUBMIT_TEMPLATE_URL =
  'https://standvast-team.atlassian.net/servicedesk/customer/portal/6';
export const BARNES_AND_NOBLES =
  'https://docs.google.com/spreadsheets/d/164Mt-hZRvp3bAQjWxBiS4KB9J-X1ytVhUoj1U_4zl74/edit?usp=sharing';
export const SHORELIGHT =
  'https://docs.google.com/spreadsheets/d/1c62CZ2SDMHPuqlrte9pG49AEEUV5dEOYoa5pOltkbMg/edit?usp=sharing';
export const WALMART_ORDER_URL =
  'https://docs.google.com/spreadsheets/d/157EJkqUo1YayGl5wS9vYUyFMRBCePvwxig-yrXc2Iac/edit?gid=1108550045#gid=1108550045';
export const WHOLESALE_ORDER_URL =
  'https://docs.google.com/spreadsheets/d/14kEKsr4VR9M52YZDBY9pcR5RLMfcHtqvJ2v_R4GfLOs/edit?gid=1108550045#gid=1108550045';
export const SHIPPING_PLAN_HELP_AND_EXAMPLE =
  'https://docs.google.com/spreadsheets/d/1X06unc9aSnh95wU6GcgXF-yTGwQr5PnNOKbPgVle6Y4/edit?gid=1190970282#gid=1190970282';
export const UPLOAD_PO_TEMPLATE_URL =
  'https://docs.google.com/spreadsheets/d/1X06unc9aSnh95wU6GcgXF-yTGwQr5PnNOKbPgVle6Y4/gviz/tq?tqx=out:csv;outFileName:upload-po-template&sheet=Template';
export const UPLOAD_ITEM_MASTER_TEMPLATE_URL =
  'https://docs.google.com/spreadsheets/d/1aApLvOeUfX_e782Bj_kDHTkOz3Mu4K85djUezoNegMs/gviz/tq?tqx=out:csv;outFileName:upload-im-template&sheet=Template';
export const UPLOAD_ITEM_MASTER_HELP_AND_EXAMPLE_URL =
  'https://docs.google.com/spreadsheets/d/1aApLvOeUfX_e782Bj_kDHTkOz3Mu4K85djUezoNegMs/edit?gid=1190970282#gid=1190970282';

export const TEMPLATE_ITEMS = [
  {
    label: 'Outbound Parcel Shipments',
    templateUrl: PARCEL_SALES_ORDER_URL,
    instructionUrl:
      'https://docs.google.com/spreadsheets/d/1WtaXkZH4mtqta5dqzwAH1Vi1Xe2GOsbq2Gt1aUZRTdI/edit?gid=1928022629#gid=1928022629',
    submitUrl:
      'https://standvast-team.atlassian.net/servicedesk/customer/portal/6/group/32/create/10142',
  },
  {
    label: 'Outbound Freight Shipments',
    templateUrl: FREIGHT_SALES_ORDER_URL,
    instructionUrl:
      'https://docs.google.com/spreadsheets/d/11FCs7Rg038bo4e2Xw7shokRkq2V5k1UHpc-MWnqd7Qo/edit?gid=1706883823#gid=1706883823',
    submitUrl:
      'https://standvast-team.atlassian.net/servicedesk/customer/portal/6/group/32/create/10142',
  },
  {
    label: 'Inbound Shipping Plans',
    templateUrl: PURCHASE_ORDER_URL,
    instructionUrl:
      'https://docs.google.com/spreadsheets/d/1X06unc9aSnh95wU6GcgXF-yTGwQr5PnNOKbPgVle6Y4/edit?usp=sharing',
    submitUrl:
      'https://standvast-team.atlassian.net/servicedesk/customer/portal/6/group/32/create/10141',
  },
  {
    label: 'Create New Item Master',
    templateUrl: CREATE_ITEM_MASTER_URL,
    instructionUrl:
      'https://docs.google.com/spreadsheets/d/1sjRQS0XhuC2oWKtdFiq-xDtuq1xV_IHbbIIztTYIYiw/edit?gid=0#gid=0',
    submitUrl:
      'https://standvast-team.atlassian.net/servicedesk/customer/portal/6/group/32/create/10151',
  },
  {
    label: 'Update Existing Item Master',
    templateUrl: UPDATE_ITEM_MASTER_URL,
    instructionUrl:
      'https://docs.google.com/spreadsheets/d/1S06eTN2Ic_wKpmrUChe6-TWPv-f0tOjLwa7zhKZL1Kw/edit?gid=0#gid=0',
    submitUrl:
      'https://standvast-team.atlassian.net/servicedesk/customer/portal/6/group/32/create/10150',
  },
  {
    label: 'Forecast Submission',
    templateUrl: FORECAST_URL,
    instructionUrl:
      'https://docs.google.com/spreadsheets/d/14pm_laOi2HEYZJvRWVJzpbJhBKkDKF0IuWcbqg77fPI/edit?gid=1691338767#gid=1691338767',
    submitUrl:
      'https://standvast-team.atlassian.net/servicedesk/customer/portal/6/group/34/create/10160',
  },
  {
    label: 'Shipping Account Setup',
    templateUrl: SHIPPING_ACCOUNT_SETUP_URL,
    instructionUrl:
      'https://docs.google.com/spreadsheets/d/1oEjRiNX2T4TxcXOkT-hftOIiP00YEezdxpScgziwpaA/edit?gid=683576964#gid=683576964',
    submitUrl:
      'https://standvast-team.atlassian.net/servicedesk/customer/portal/6/group/34/create/10160',
  },
];

export const TEMPLATE_ITEMS_BY_ORG: Record<
  string,
  Array<typeof TEMPLATE_ITEMS[0]>
> = {
  DMF: [
    {
      label: 'Barnes & Nobles',
      templateUrl: BARNES_AND_NOBLES,
      instructionUrl: '',
      submitUrl: '',
    },
    {
      label: 'Shorelight',
      templateUrl: SHORELIGHT,
      instructionUrl: '',
      submitUrl: '',
    },
  ],
  SCS: [
    {
      label: 'Walmart Order',
      templateUrl: WALMART_ORDER_URL,
      instructionUrl: '',
      submitUrl:
        'https://standvast-team.atlassian.net/servicedesk/customer/portal/6/group/32/create/10142',
    },
    {
      label: 'Wholesale Order',
      templateUrl: WHOLESALE_ORDER_URL,
      instructionUrl:
        'https://docs.google.com/spreadsheets/d/14kEKsr4VR9M52YZDBY9pcR5RLMfcHtqvJ2v_R4GfLOs/edit?gid=914371956#gid=914371956',
      submitUrl:
        'https://standvast-team.atlassian.net/servicedesk/customer/portal/6/group/32/create/10142',
    },
  ],
};
