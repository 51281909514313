import { SELECT_NODE_OPTION } from 'queries/warehouses/useWarehouses';
import { CarrierAccount } from 'types/shipVia';
import flatten from 'lodash-es/flatten';
import uniq from 'lodash-es/uniq';
import {
  defaultShippingAccountCountry,
  usAndTerritories,
} from 'features/create-order/CarrierAccountSelect';

export function filterShipVias(
  carrierAccounts: CarrierAccount[],
  props?: {
    carrierAccountUUID?: string;
    warehouseID?: string;
    country?: string;
    isNetworkOrder?: boolean;
  }
) {
  let filteredData = [];
  if (props?.isNetworkOrder) {
    filteredData = carrierAccounts;
  } else {
    const isNodeOptionSelected = props?.warehouseID === SELECT_NODE_OPTION.code;
    filteredData = carrierAccounts.filter((item) => {
      const isCarrierAccountMatch =
        (isNodeOptionSelected && !props?.carrierAccountUUID) ||
        item.carrierAccountUuid === props?.carrierAccountUUID;

      const isWarehouseMatch =
        isNodeOptionSelected ||
        item.distributionCenterCode === props?.warehouseID;

      const isValidCountry = usAndTerritories.includes(props?.country ?? '')
        ? item.countryCode === props?.country
        : item.countryCode === defaultShippingAccountCountry;

      return isCarrierAccountMatch && isWarehouseMatch && isValidCountry;
    });
  }
  return uniq(flatten(filteredData.map((item) => item.shipVias)));
}
