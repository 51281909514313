/* istanbul ignore file */
import * as yup from 'yup';
import { carrierInfoSchema } from './formParts/carrierInfo';
import { shipToAddressSchema } from './formParts/shipToAddress';
import { basicNetworkOrderInfo } from './formParts/basicNetworkOrderInfo';
import { orderLinesSchema } from './formParts/orderLines';

export const createNetworkOrderSchema = basicNetworkOrderInfo
  .concat(carrierInfoSchema.pick(['shipOption']))
  .concat(
    yup.object({
      carrierAccountUUID: yup.string().label('Carrier Billing Account').max(36),
      shipTo: shipToAddressSchema,
      lines: orderLinesSchema,
    })
  );
