/* eslint-disable react-hooks/exhaustive-deps */
import {
  RATE_SHOP_OPTION,
  useCarrierAccounts,
} from 'queries/shipVias/useCarrierAccounts';
import { useEffect } from 'react';
import { useForm, UseFormProps } from 'react-hook-form';
import { CreateNetworkOrder } from 'types/networkOrder';

export const useNetworkOrderForm = ({
  defaultValues,
  ...formProps
}: UseFormProps<CreateNetworkOrder>) => {
  const { data: carrierAccounts } = useCarrierAccounts();

  const reactHookFormContext = useForm<CreateNetworkOrder>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues,
    context: {
      carrierAccounts,
    },
    ...formProps,
  });
  const {
    watch,
    setValue,
    trigger,
    formState: { isDirty },
  } = reactHookFormContext;

  const shipViaValue = watch('shipVia');
  const shipOptionValue = watch('shipOption');
  const country = watch('shipTo.country');

  useEffect(() => {
    if (!isDirty || !shipOptionValue) return;
    trigger('shipOption');
  }, [shipOptionValue, isDirty]);

  useEffect(() => {
    if ((country && country !== 'US') || shipViaValue !== RATE_SHOP_OPTION) {
      setValue('shipOption', undefined);
      trigger('shipOption');
    }
  }, [shipViaValue, country]);

  return reactHookFormContext;
};
