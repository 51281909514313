import { UNIT_OF_MEASURE_EACHES } from 'constants/unitOfMeasure';
import {
  CrudActionType,
  DtoFieldError,
  DtoFieldErrorSegment,
} from 'hooks/useCrudActionUserFeedback';
import {
  PurchaseOrder,
  PurchaseOrderLine,
  PurchaseOrderLineDto,
  PurchaseOrderDto,
  PurchaseOrderPreviewRow,
  PurchaseOrderPreview,
  PurchaseOrderUploadErrorDto,
  PurchaseOrderUploadError,
} from 'types/purchaseOrders';
import { Warehouse } from 'types/warehouses';
import { formatDate } from 'utils/dateUtils';
import _merge from 'lodash-es/merge';
import { ValuesType } from 'types/utilities';

export function mapLinesToDtos(
  lines: PurchaseOrderLine[]
): PurchaseOrderLineDto[] {
  return lines?.map((line) => ({
    line_number: line.lineNo,
    sku: line.sku,
    description: line.description,
    uom: UNIT_OF_MEASURE_EACHES,
    external_item_number: line.customerItemNumber,
    order_qty: +line.orderQty,
    received_qty: line.receivedQty,
    short_qty: line.shortQty,
    damaged_qty: line.damagedQty,
    overage_qty: line.overageQty,
  }));
}

export function mapPurchaseOrderToDto(order: PurchaseOrder): PurchaseOrderDto {
  return {
    po_number: order.poNumber,
    vendor_name: order.vendorName,
    container_numbers: order.containerNumbers.map(
      ({ containerNumber }) => containerNumber
    ),
    created_date: order.createdDate,
    target_dc: order.warehouseID,
    requested_delivery_date: formatDate(order.requestedDate, 'YYYY-MM-DD', {
      timeZone: 'UTC',
    }),
    purchase_order_lines: mapLinesToDtos(order.lines),
    delivery_address: order.deliveryAddress
      ? {
          name: order.deliveryAddress.name,
          address: order.deliveryAddress.address,
          city: order.deliveryAddress.city,
          state: order.deliveryAddress.state,
          zip_code: order.deliveryAddress.zipCode,
          country: order.deliveryAddress.country,
          email: order.deliveryAddress.email,
          phone_number: order.deliveryAddress.phoneNumber,
        }
      : undefined,
    status_history: order.statusChangesHistory?.map((entry) => ({
      status: entry.status,
      date: entry.status_date,
      status_error_message: '',
    })),
  };
}

export function mapFieldDtoErrorToRhfPath(
  error: DtoFieldError,
  action?: CrudActionType
) {
  const path = [];
  const segments = error.loc?.slice(2) ?? [];

  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i];
    let mappedSegment = null;

    if (i === 0) {
      mappedSegment = mapTopLevelDtoSegmentToRhfSegment(segment, action);
    } else if (segments[0] === 'purchase_order_lines' && i === 1) {
      mappedSegment = segment;
    } else if (segments[0] === 'purchase_order_lines' && i === 2) {
      mappedSegment = mapLinesDtoSegmentToRhfSegment(segment);
    }

    path.push(mappedSegment);
  }

  return path.length === 0 || path.some((s) => s === null)
    ? null
    : path.join('.');
}

function mapTopLevelDtoSegmentToRhfSegment(
  segment: DtoFieldErrorSegment,
  action?: CrudActionType
) {
  return (
    {
      requested_delivery_date: 'requestedDate',
      purchase_order_lines: 'lines',
      ...(action === 'CREATE'
        ? {
            target_dc: 'warehouseID',
            po_number: 'poNumber',
          }
        : {}),
    }[segment] ?? null
  );
}

function mapLinesDtoSegmentToRhfSegment(segment: DtoFieldErrorSegment) {
  return (
    {
      sku: 'sku',
      external_item_number: 'customerItemNumber',
      order_qty: 'orderQty',
    }[segment] ?? null
  );
}

export function mapRowsToPurchaseOrdersPreview(
  rows: PurchaseOrderPreviewRow[],
  warehouse: Warehouse
): PurchaseOrderPreview[] {
  const uploadPurchaseOrders: { [key: string]: PurchaseOrderPreview } = {};
  let lineNo = 1;

  rows.forEach((item) => {
    if (!item.po_number) return;

    let qtyOrdered = parseInt(item.qty_ordered ?? '0');

    const purchaseOrderPreview: PurchaseOrderPreview = {
      warehouseID: warehouse.code,
      poNumber: item.po_number,
      requestedDate: item.requested_delivery_date,
      containerNumbers: item.container_number
        ? item.container_number.split(',').map((c) => ({
            containerNumber: c.trim(),
          }))
        : undefined,
      deliveryAddress: warehouse.address
        ? {
            address: warehouse.address.address,
            city: warehouse.address.city,
            state: warehouse.address.state,
            zipCode: warehouse.address.zip,
            country: 'US',
          }
        : undefined,
    };

    let lineItem: ValuesType<PurchaseOrderPreview['lines']>;
    if (item.sku) {
      lineItem = {
        lineNo: lineNo.toString(),
        sku: item.sku,
        unitOfMeasure: 'EA',
        orderQty: isNaN(qtyOrdered) ? 0 : qtyOrdered,
        customerItemNumber: item.customer_item_number,
      };
      lineNo++;
    }

    if (!uploadPurchaseOrders[item.po_number]) {
      uploadPurchaseOrders[item.po_number] = {
        ...purchaseOrderPreview,
        lines: lineItem ? [lineItem] : [],
      };
    } else {
      uploadPurchaseOrders[item.po_number] = _merge(
        uploadPurchaseOrders[item.po_number],
        purchaseOrderPreview
      );
      if (lineItem) {
        uploadPurchaseOrders[item.po_number].lines!.push(lineItem);
      }
    }
  });

  return Object.values(uploadPurchaseOrders);
}

export function mapDtoToPurchaseOrderUploadError(
  dto: PurchaseOrderUploadErrorDto,
  previews: PurchaseOrderPreview[]
): PurchaseOrderUploadError[] {
  const errors: PurchaseOrderUploadError[] = [];

  for (const [poNumber, error] of Object.entries(dto)) {
    if (poNumber === 'request_errors') {
      const baseError: PurchaseOrderUploadError = {
        errors: [error as any],
      };
      errors.push(baseError);
      continue;
    }

    const preview = previews.find(
      (p) => p.poNumber?.toUpperCase() === poNumber
    );
    const lineErrors = Object.entries(error.line ?? {});

    const baseError: PurchaseOrderUploadError = {
      poNumber,
      containerNumbers: preview?.containerNumbers
        ?.map((c) => c.containerNumber)
        .join(','),
      requestedDate: preview?.requestedDate,
      errors: error.errors ?? [],
    };

    if (baseError.errors.length) {
      errors.push(baseError);
    }

    if (lineErrors.length) {
      for (const [lineNo, lineError] of lineErrors) {
        const line = preview?.lines?.find((l) => l.lineNo === lineNo);
        errors.push({
          ...baseError,
          sku: line?.sku,
          orderQty: line?.orderQty,
          errors: lineError.errors,
        });
      }
    }
  }
  return errors;
}
