import {
  ItemMasterCustomsInfo,
  ItemMasterCustomsInfoDto,
  ItemMasterUploadCsvRow,
  ItemMasterUploadErrorDto,
  ItemMasterUploadErrorV2,
  ItemMasterUploadPreview,
} from 'types/itemMaster';

export function mapRowsToSkuList(rows: ItemMasterUploadCsvRow[]) {
  return rows
    .filter((row) => Boolean(row.client_sku))
    .map((row) => row.client_sku!);
}

export function mapRowsToItemMasterUploadPreview(
  rows: ItemMasterUploadCsvRow[]
): ItemMasterUploadPreview[] {
  return rows
    .filter((row) => Boolean(row.client_sku))
    .map((row) => ({
      sku: row.client_sku!,
      upc: row.upc ?? '',
      standvastSku: row.standvast_sku ?? '',
      description: row.description ?? '',
      height: row.height ?? '',
      length: row.length ?? '',
      width: row.width ?? '',
      weight: row.weight ?? '',
      hazmatUnNumber: row.hazmat_un_number ?? '',
      itemValue: row.item_value ?? '',
      lotTrackingRequired: row.lot_tracking_required ?? '',
      harmonizedTariffCode: row.harmonized_tariff_code ?? '',
      commodifyDescription: row.commodify_description ?? '',
      countryOfManufacture: row.country_of_manufacture ?? '',
      customsWeight: row.customs_weight ?? '',
      customsValue: row.customs_value ?? '',
    }));
}

export function mapDtoToItemMasterCustomsInfo(
  dto: ItemMasterCustomsInfoDto
): ItemMasterCustomsInfo[] {
  const customsInfo: ItemMasterCustomsInfo[] = [];
  for (const sku in dto) {
    customsInfo.push({
      sku: sku,
      isValidItemMaster: dto[sku].is_valid_item_master,
      hasCustomsInfo: dto[sku].has_customs_info,
      customsInfo: dto[sku].customs_info,
    });
  }
  return customsInfo;
}

export function mapDtoToItemMasterUploadError(
  dto: ItemMasterUploadErrorDto,
  previewData: ItemMasterUploadPreview[]
): ItemMasterUploadErrorV2[] {
  const errors =
    dto.line_errors?.map((line) => ({
      ...previewData.find((item) => item.sku === line.sku)!,
      errors: line.errors,
    })) ?? [];
  if (dto.request_error) {
    errors.unshift({
      errors: [dto.request_error],
    } as ItemMasterUploadErrorV2);
  }
  return errors;
}
