import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from 'react-router-dom';
import { StoreProvider } from 'contexts/storeContext';
import { Auth0ProviderWithRedirectCallback } from 'auth0-provider';
import { OrderDetailsPage } from 'pages/OrderDetailsPage';
import { CreateOrderPage } from 'pages/CreateOrderPage';
import { PurchaseOrderDetailsPage } from 'pages/PurchaseOrderDetailsPage';
import { NotImplementedPage } from 'pages/NotImplementedPage';
import { OrdersPage } from 'pages/OrdersPage';
import { DashboardPage } from 'pages/DashboardPage';
import { AdminPage } from 'pages/AdminPage';
import { InventoryPage } from 'pages/InventoryPage';
import { InventoryItemDetailsPage } from 'pages/InventoryItemDetailsPage';
import { PurchaseOrderPage } from 'pages/PurchaseOrderPage';
import { SchedulePurchaseOrderPage } from 'pages/SchedulePurchaseOrderPage';
import { MainLayout } from 'layouts/MainLayout';
import { ProtectedRoute } from 'components/ProtectedRoute';
import { AsnPage } from 'pages/AsnPage';
import { ReturnsPage } from 'pages/ReturnsPage';
import { CONFIG } from 'utils/config';
import { TemplatesPage } from 'pages/TemplatesPage';
import { ItemMastersPage } from 'pages/ItemMastersPage';
import { UploadItemMastersPage } from 'pages/UploadItemMastersPage';
import { MsaDocumentsPage } from 'pages/MsaDocumentsPage';
import { TransportationDetailsPage } from 'pages/TransportationDetailsPage';
import { AUTH0_ROLES } from 'constants/auth0Roles';
import { ReRouteOrderPage } from 'pages/ReRouteOrderPage';
import { BulkCancelPage } from 'pages/BulkCancelPage';
import { NetworkOrdersPage } from 'pages/NetworkOrdersPage';
import { NetworkOrderDetailsPage } from 'pages/NetworkOrderDetailsPage';
import { UploadPurchaseOrdersPage } from 'pages/UploadPurchaseOrdersPage';
import { CreateNetworkOrderPage } from 'pages/CreateNetworkOrderPage';
import { ModifyOrderPage } from 'pages/ModifyOrderPage';
import { CancelOrderPage } from 'pages/CancelOrderPage';
import { ModifyPurchaseOrderPage } from 'pages/ModifyPurchaseOrderPage';
import { CancelPurchaseOrderPage } from 'pages/CancelPurchaseOrderPage';
import { UploadItemMastersPageV2 } from 'pages/UploadItemMastersPageV2';

export const Router = () => {
  return (
    <BrowserRouter>
      <StoreProvider>
        <Auth0ProviderWithRedirectCallback>
          <Routes>
            <Route path="/" element={<ProtectedRoute component={MainLayout} />}>
              <Route index element={<Navigate replace to="dashboard" />} />
              <Route
                path="dashboard"
                element={<ProtectedRoute component={DashboardPage} />}
              >
                <Route path=":salesOrderNumber">
                  <Route
                    path="edit"
                    element={<ProtectedRoute component={OrderDetailsPage} />}
                  />
                  <Route
                    path="cancel"
                    element={<ProtectedRoute component={OrderDetailsPage} />}
                  />
                  <Route
                    path=""
                    element={<ProtectedRoute component={OrderDetailsPage} />}
                  />
                </Route>
              </Route>
              <Route path="network-orders">
                <Route
                  path=""
                  element={
                    <ProtectedRoute
                      component={NetworkOrdersPage}
                      allowTenants={CONFIG().networkOrderTenants}
                    />
                  }
                >
                  <Route path=":orderName">
                    <Route
                      path=""
                      element={
                        <ProtectedRoute
                          component={NetworkOrderDetailsPage}
                          allowTenants={CONFIG().networkOrderTenants}
                        />
                      }
                    />
                  </Route>
                </Route>
                {CONFIG().featureToggles.enableCreateNetworkOrders ? (
                  <Route
                    path="create"
                    element={
                      <ProtectedRoute
                        component={CreateNetworkOrderPage}
                        allowTenants={CONFIG().networkOrderTenants}
                      />
                    }
                  />
                ) : null}
              </Route>
              <Route path="orders">
                <Route
                  path=""
                  element={<ProtectedRoute component={OrdersPage} />}
                >
                  <Route
                    path="bulk-cancel"
                    element={
                      <ProtectedRoute
                        component={BulkCancelPage}
                        requireOneOfRoles={[AUTH0_ROLES.STANDVAST_ADMIN]}
                      />
                    }
                  />
                </Route>
                <Route path=":salesOrderNumber">
                  <Route
                    path=""
                    element={<ProtectedRoute component={OrderDetailsPage} />}
                  >
                    <Route
                      path="re-route"
                      element={
                        <ProtectedRoute
                          component={ReRouteOrderPage}
                          requireOneOfRoles={[AUTH0_ROLES.STANDVAST_ADMIN]}
                        />
                      }
                    />
                    <Route
                      path="cancel"
                      element={<ProtectedRoute component={CancelOrderPage} />}
                    />
                  </Route>
                  <Route
                    path="edit"
                    element={<ProtectedRoute component={ModifyOrderPage} />}
                  />
                </Route>
                {CONFIG().featureToggles.enableCreateSalesOrder ? (
                  <Route
                    path="create"
                    element={<ProtectedRoute component={CreateOrderPage} />}
                  />
                ) : null}
              </Route>
              <Route path="inventory">
                <Route
                  path=""
                  element={<ProtectedRoute component={InventoryPage} />}
                />
                <Route
                  path=":sku"
                  element={
                    <ProtectedRoute component={InventoryItemDetailsPage} />
                  }
                />
              </Route>
              <Route
                path="admin"
                element={
                  <ProtectedRoute
                    component={AdminPage}
                    requireOneOfRoles={[
                      AUTH0_ROLES.SUPER_USER,
                      AUTH0_ROLES.STANDVAST_ADMIN,
                    ]}
                  />
                }
              />
              <Route path="inbound-inventory">
                <Route
                  path=""
                  element={<ProtectedRoute component={PurchaseOrderPage} />}
                />
                <Route
                  path="upload"
                  element={
                    <ProtectedRoute component={UploadPurchaseOrdersPage} />
                  }
                />
                <Route path=":purchaseOrderNumber">
                  <Route
                    path="edit"
                    element={
                      <ProtectedRoute component={ModifyPurchaseOrderPage} />
                    }
                  />
                  <Route
                    path=""
                    element={
                      <ProtectedRoute component={PurchaseOrderDetailsPage} />
                    }
                  >
                    <Route
                      path="cancel"
                      element={
                        <ProtectedRoute component={CancelPurchaseOrderPage} />
                      }
                    />
                  </Route>
                </Route>
                <Route
                  path="schedule"
                  element={
                    <ProtectedRoute component={SchedulePurchaseOrderPage} />
                  }
                />
              </Route>
              <Route
                path="team-admin"
                element={<ProtectedRoute component={NotImplementedPage} />}
              />
              {CONFIG().featureToggles.enableAsnReport ? (
                <Route
                  path="/report/asns"
                  element={<ProtectedRoute component={AsnPage} />}
                />
              ) : null}
              {CONFIG().featureToggles.enableTransportationDetailsReport ? (
                <Route
                  path="/report/transportation-details"
                  element={
                    <ProtectedRoute
                      component={TransportationDetailsPage}
                      requireOneOfRoles={[
                        AUTH0_ROLES.STANDVAST_ADMIN,
                        AUTH0_ROLES.FINANCIAL_DATA_ACCESS,
                      ]}
                    />
                  }
                />
              ) : null}
              {CONFIG().featureToggles.enableReturnsReport ? (
                <Route
                  path="/report/returns"
                  element={<ProtectedRoute component={ReturnsPage} />}
                />
              ) : null}
              <Route
                path="templates"
                element={<ProtectedRoute component={TemplatesPage} />}
              />
              {CONFIG().featureToggles.enableItemMaster ? (
                <Route
                  path="item-masters"
                  element={<ProtectedRoute component={Outlet} />}
                >
                  <Route
                    index
                    element={<ProtectedRoute component={ItemMastersPage} />}
                  />
                  <Route
                    path="create"
                    element={
                      <ProtectedRoute
                        component={UploadItemMastersPage}
                        requireOneOfRoles={[AUTH0_ROLES.STANDVAST_ADMIN]}
                      />
                    }
                  />
                  <Route
                    path="update"
                    element={
                      <ProtectedRoute
                        component={UploadItemMastersPage}
                        requireOneOfRoles={[AUTH0_ROLES.STANDVAST_ADMIN]}
                      />
                    }
                  />
                  {CONFIG().featureToggles.enableUpsertItemMasters ? (
                    <Route
                      path="upload"
                      element={
                        <ProtectedRoute
                          component={UploadItemMastersPageV2}
                          requireOneOfRoles={[AUTH0_ROLES.STANDVAST_ADMIN]}
                        />
                      }
                    />
                  ) : null}
                </Route>
              ) : null}
              <Route
                path="msa"
                element={
                  <ProtectedRoute
                    component={MsaDocumentsPage}
                    requireOneOfRoles={[
                      AUTH0_ROLES.STANDVAST_ADMIN,
                      AUTH0_ROLES.FINANCIAL_DATA_ACCESS,
                    ]}
                  />
                }
              />
            </Route>
            <Route
              path="*"
              element={<ProtectedRoute component={NotImplementedPage} />}
            />
          </Routes>
        </Auth0ProviderWithRedirectCallback>
      </StoreProvider>
    </BrowserRouter>
  );
};
