import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import {
  ItemMasterUploadResponse,
  ItemMasterUploadErrorV2,
  ItemMasterUploadPreview,
} from 'types/itemMaster';
import { mapDtoToItemMasterUploadError } from './mappers';

export function useUploadItemMasters() {
  const queryClient = useQueryClient();
  return useMutation<
    ItemMasterUploadResponse,
    ItemMasterUploadErrorV2[],
    { file: File; data: ItemMasterUploadPreview[] }
  >({
    mutationFn: async ({ file, data }) => {
      const formData = new FormData();
      formData.append('file', file);
      try {
        const response = await axios.put('/itemmaster', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        return response.data;
      } catch (error: any) {
        const errors = (error as AxiosError<any>)?.response?.data;
        throw mapDtoToItemMasterUploadError(errors, data);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['itemMasters'] });
    },
  });
}
