import { DestinationForm } from 'features/create-order/DestinationForm';
import {
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useSteps,
} from '@chakra-ui/react';
import RestoreIcon from '@mui/icons-material/Restore';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { SalesOrder } from 'types/salesOrders';
import {
  buildEmptyOrder,
  buildInittialLines,
} from 'queries/salesOrders/mappers';
import { useCrudActionUserFeedback } from 'hooks/useCrudActionUserFeedback';
import { useCreateSalesOrder } from 'mutations/salesOrders/useCreateSalesOrder';
import { sendCreateSalesOrderEvent } from 'utils/monitorEvents';
import { useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSalesOrderForm } from 'hooks/useSalesOrderForm';
import merge from 'lodash-es/merge';
import { useLocation, useNavigate } from 'react-router-dom';
import { mapFieldDtoErrorToRhfPath } from 'mutations/salesOrders/mappers';
import { FormProvider } from 'react-hook-form';
import { createFreightSalesOrderSchema } from 'schemas/createFreightSalesOrder';
import { createParcelSalesOrderSchema } from 'schemas/createParcelSalesOrder';
import { LineItemsForm } from 'features/create-order/LineItemsForm';
import { ShippingAndBillingForm } from 'features/create-order/ShippingAndBillingForm';
import ErrorIcon from '@mui/icons-material/Error';
import { ERROR_RED } from 'theme/ui-palette';
import has from 'lodash-es/has';
import { RATE_SHOP_OPTION } from 'queries/shipVias/useCarrierAccounts';
import { SELECT_NODE_OPTION } from 'queries/warehouses/useWarehouses';

const sessionStorageKey = 'create_so_draft';

export function CreateOrderPage() {
  const { activeStep, setActiveStep, goToNext, goToPrevious } = useSteps({
    index: 0,
    count: 2,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const { openConfirmationDialog } = useConfirmationDialog();

  const defaultOrderValue = useMemo(() => {
    const draftJson = sessionStorage.getItem(sessionStorageKey);
    const emptyOrder = buildEmptyOrder();
    const draftData = draftJson ? { ...JSON.parse(draftJson) } : null;
    const reshipOrder = location.state as SalesOrder;
    if (reshipOrder?.type) {
      return merge(emptyOrder, reshipOrder);
    }
    if (draftData) {
      return merge(
        emptyOrder,
        draftData.lines.length
          ? draftData
          : { ...draftData, lines: buildInittialLines() }
      );
    }
    return emptyOrder;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formContext = useSalesOrderForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultOrderValue,
    resolver: yupResolver(
      defaultOrderValue.type === 'Freight'
        ? createFreightSalesOrderSchema
        : createParcelSalesOrderSchema
    ),
  });
  const {
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
    watch,
  } = formContext;

  const orderType = watch('type');

  const onClose = () => {
    navigate(`/orders`);
  };

  const resetForm = () => {
    reset(
      {
        ...buildEmptyOrder(),
        shipVia: RATE_SHOP_OPTION,
        warehouseID: SELECT_NODE_OPTION.code,
        lines: buildInittialLines(),
      },
      { keepDefaultValues: false }
    );
    sessionStorage.removeItem(sessionStorageKey);
  };

  const { mutateAsync, isLoading: isSubmitting } = useCreateSalesOrder();

  const handleCreateOrder = useCrudActionUserFeedback<SalesOrder>({
    mutateAsync: () => mutateAsync(getValues()),
    actionType: 'CREATE',
    successMessage: 'Shipment successfully created',
    successCallback: () => {
      sendCreateSalesOrderEvent(getValues().type, getValues().soNumber);
      resetForm();
      onClose();
    },
    setFieldError: formContext.setError,
    mapFieldDtoErrorToRhfPath,
  });

  const handleResetClick = () => {
    openConfirmationDialog({
      title: 'Discard changes',
      message: 'Discard changes?',
      onConfirm: () => {
        resetForm();
        setActiveStep(0);
      },
      blockScrollOnMount: false,
      confirmButtonLabel: 'Discard',
    });
  };

  const hasErrorDetinationForm = ['shipTo', 'soNumber'].some((key) =>
    has(errors, key)
  );

  const hasErrorLineItemsForm = ['lines', 'warehouseID'].some((key) =>
    has(errors, key)
  );

  const hasErrorShippingAndBillingForm =
    orderType === 'Parcel'
      ? ['carrierAccountUUID', 'shipVia', 'shipOption'].some((key) =>
          has(errors, key)
        )
      : false;

  useEffect(() => {
    return () => {
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(getValues()));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...formContext}>
      <Flex mb={{ base: 2, md: 6 }} alignItems="center" gap={2}>
        <Text as="h2" variant="pageTitle">
          Create Shipment
        </Text>
        <Button
          px={4}
          py={2}
          fontWeight="normal"
          rightIcon={<RestoreIcon />}
          variant="ghost"
          onClick={handleResetClick}
        >
          Reset
        </Button>
      </Flex>
      <Tabs isLazy index={activeStep} onChange={setActiveStep}>
        <TabList>
          <Tab>
            Step 1: Destination
            {hasErrorDetinationForm && (
              <ErrorIcon sx={{ ml: 0.5, color: ERROR_RED }} />
            )}
          </Tab>
          <Tab>
            Step 2: Line Items
            {hasErrorLineItemsForm && (
              <ErrorIcon sx={{ ml: 0.5, color: ERROR_RED }} />
            )}
          </Tab>
          <Tab>
            Step 3: Shipping & Billing
            {hasErrorShippingAndBillingForm && (
              <ErrorIcon sx={{ ml: 0.5, color: ERROR_RED }} />
            )}
          </Tab>
        </TabList>

        <TabPanels mt={2}>
          <TabPanel>
            <DestinationForm onGoToNext={goToNext} />
          </TabPanel>
          <TabPanel>
            <LineItemsForm
              onGoToNext={goToNext}
              onGoToPrevious={goToPrevious}
            />
          </TabPanel>
          <TabPanel>
            <ShippingAndBillingForm
              isSubmitting={isSubmitting}
              onSubmit={handleSubmit(handleCreateOrder)}
              onGoToPrevious={goToPrevious}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </FormProvider>
  );
}
