/* istanbul ignore file */
import { useFormContext } from 'react-hook-form';
import { FormTextarea } from 'components/FormTextarea';
import { SalesOrder } from 'types/salesOrders';

type Props = {
  tabIndex?: number;
};

export const GiftMessageForm: React.FC<Props> = ({ tabIndex }) => {
  const { register } = useFormContext<SalesOrder>();

  return (
    <FormTextarea
      tabIndex={tabIndex}
      placeholder="Optional"
      label="Gift Message"
      {...register('giftMessage')}
      maxLength={500}
      rows={2}
      tooltip="Recipient will see this message in the shipment"
    />
  );
};
