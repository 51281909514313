import {
  Button,
  ListItem,
  Text,
  Tooltip,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { Shimmer } from 'components/Shimmer';
import { usePurchaseOrderContact } from 'hooks/usePurchaseOrderContact';
import { useProcessedContainers } from 'queries/purchaseOrders/useProcessedContainers';
import { PurchaseOrder } from 'types/purchaseOrders';
import { formatDate } from 'utils/dateUtils';

type Props = {
  order?: PurchaseOrder;
};

export function Appointments({ order }: Props) {
  const { url } = usePurchaseOrderContact(
    order?.poNumber ? [order?.poNumber] : []
  );
  const { data: processedContainers } = useProcessedContainers(order?.poNumber);
  const latestContainer = processedContainers?.[0];

  return (
    <VStack spacing={2} align="flex-start">
      <Text variant="boldBlue" fontSize="xl">
        Appointments
      </Text>
      <UnorderedList fontWeight={300}>
        {latestContainer?.carrierDeliveryAppointment ? (
          <ListItem>
            <Tooltip label="Scheduled time as provided from the transportation carrier.">
              <Text>
                Scheduled Delivery Time{' '}
                {formatDate(
                  latestContainer?.carrierDeliveryAppointment,
                  'MM/DD/YYYY',
                  {
                    hour: 'numeric',
                    minute: '2-digit',
                    timeZoneName: 'short',
                  }
                )}
              </Text>
            </Tooltip>
          </ListItem>
        ) : null}
        <ListItem>
          <Shimmer
            w="160px"
            h="20px"
            display="inline-block"
            verticalAlign="top"
            isLoaded={!!order}
          >
            <Tooltip label="Originally requested date when you created this shipping plan">
              <Text>
                Requested Delivery Date{' '}
                {formatDate(order?.requestedDate, 'MM/DD/YYYY', {
                  timeZone: 'UTC',
                })}
              </Text>
            </Tooltip>
          </Shimmer>
        </ListItem>
      </UnorderedList>
      <Button as="a" href={url} target="_blank" size="md">
        Contact Standvast Transportation
      </Button>
    </VStack>
  );
}
