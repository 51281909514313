import { IdToken, useAuth0 } from '@auth0/auth0-react';
import { useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Box,
  Flex,
  IconButton,
  Image,
  Stack,
  useBreakpointValue,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import MenuIcon from '@mui/icons-material/Menu';
import { Outlet } from 'react-router-dom';
import { Link } from 'components/Link';
import { SearchBox } from 'features/search-box/SearchBox';
import logoImg from 'assets/sv-logo.svg';
import nameAndLogoImg from 'assets/sv-name-and-logo.svg';
import { LayoutBox, MAIN_BOX_TOP_SPACING } from './components/LayoutBox';
import { NavMenu } from './components/NavMenu';
import { AuthContextProvider } from 'contexts/authContext';
import { CONFIG } from 'utils/config';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { BANNER_HEIGHT, Banner } from 'components/Banner';
import { persistBannerClosed, retrieveBannerClosed } from 'utils/storageUtils';
import IdeaSubmission from './components/IdeaSubmission';
import { AppContextProvider } from 'contexts/appContext';
import HelpSupport from './components/HelpSupport';
import { Profile } from './components/Profile';
import { TenantSwitcher } from './components/TenantSwitcher';
import { useAuth0Roles } from 'hooks/useAuth0Roles';
import { addLineBreak } from 'utils/stringUtils';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

export function MainLayout() {
  const isAdminEnabled = useAuth0Roles();
  const { isAuthenticated, getAccessTokenSilently, getIdTokenClaims, user } =
    useAuth0();
  const [userClaims, setUserClaims] = useState<IdToken>();
  const [token, setToken] = useState<string | undefined>();
  const [showBanner, setShowBanner] = useState<boolean>(
    !retrieveBannerClosed()
  );
  const hasBannerMessage = CONFIG().bannerMessage.trim().length > 0;

  useMemo(async () => {
    setToken(await getAccessTokenSilently());
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (!user || !CONFIG().featureToggles.enableDataDogRUM) return;
    datadogRum.setUser(user);
    datadogLogs.setUser(user);
  }, [user]);

  useMemo(async () => {
    setUserClaims(await getIdTokenClaims());
  }, [getIdTokenClaims]);

  const {
    isOpen: isMainMenuOpen,
    onOpen: openMainMenu,
    onClose: closeMainMenu,
  } = useDisclosure();

  const logo = useBreakpointValue(
    { base: logoImg, md: nameAndLogoImg },
    {
      fallback: 'base',
    }
  );

  const handleCloseBanner = () => {
    setShowBanner(() => false);
    persistBannerClosed();
  };

  if (!isAuthenticated) return null;

  return (
    <AppContextProvider>
      <Box>
        {hasBannerMessage ? (
          <Banner onClose={handleCloseBanner} show={showBanner}>
            {addLineBreak(CONFIG().bannerMessage)}
          </Banner>
        ) : null}
        <LayoutBox
          variant="headerBox"
          as="header"
          top={
            hasBannerMessage && showBanner ? `${BANNER_HEIGHT}px` : undefined
          }
        >
          <Flex alignItems="center">
            <IconButton
              onClick={
                isMainMenuOpen
                  ? closeMainMenu
                  : () => {
                      openMainMenu();
                    }
              }
              title="Main menu"
              aria-label="Open main menu"
              aria-controls="main-menu"
              aria-expanded={isMainMenuOpen}
              fontSize="40px"
              icon={<MenuIcon fontSize="inherit" />}
              variant="ghost"
              size="md"
            />
            <Stack direction="row" alignItems="center">
              <Link
                to={`/dashboard`}
                display="flex"
                alignItems="center"
                p={2}
                sx={{ '&:hover': { textDecoration: 'none' } }}
              >
                <Image src={logo} alt="Standvast" />
                {CONFIG().envBanner && (
                  <Badge ml="3" p={1} fontSize="2xl" colorScheme="red">
                    {CONFIG().envBanner}
                  </Badge>
                )}
              </Link>
              {isAdminEnabled ? (
                <TenantSwitcher />
              ) : (
                <Text as="h2" fontSize="2xl" fontWeight="500" color="gray.800">
                  {userClaims?.['organization_name']}
                </Text>
              )}
            </Stack>
          </Flex>
          <Box display="flex" alignItems="center">
            {isAuthenticated ? (
              <ErrorBoundary size="compact">
                <SearchBox />
              </ErrorBoundary>
            ) : (
              <Box w="40px" />
            )}
            <Box>
              <IdeaSubmission />
            </Box>
            <Box>
              <HelpSupport />
            </Box>
            <Box>
              <Profile />
            </Box>
          </Box>
        </LayoutBox>
        <NavMenu
          isMainMenuOpen={isMainMenuOpen}
          closeMainMenu={closeMainMenu}
        />
        <LayoutBox
          id="mainBox"
          variant="mainBox"
          as="main"
          paddingTop={
            hasBannerMessage && showBanner
              ? `${BANNER_HEIGHT + MAIN_BOX_TOP_SPACING}px`
              : `${MAIN_BOX_TOP_SPACING}px`
          }
        >
          <AuthContextProvider value={{ token }}>
            <Outlet />
          </AuthContextProvider>
        </LayoutBox>
      </Box>
    </AppContextProvider>
  );
}
