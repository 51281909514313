import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { ItemMasterCustomsInfoDto } from 'types/itemMaster';
import { mapDtoToItemMasterCustomsInfo } from './mappers';

export function useItemMasterCustomsInfo() {
  return useMutation({
    mutationFn: async (payload: { tenantUuid: string; skuList: string[] }) => {
      const { data } = await axios.post<ItemMasterCustomsInfoDto>(
        '/itemmaster/customs-info/search-by-skus',
        {
          tenant_uuid: payload.tenantUuid,
          sku_list: payload.skuList,
        }
      );
      return mapDtoToItemMasterCustomsInfo(data);
    },
  });
}
