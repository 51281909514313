import { SpaceProps } from '@chakra-ui/react';
import { PaginatedTable } from 'components/PaginatedTable';
import { ToggleVisibility } from 'components/ToggleVisibility';
import { useLayoutPaginatedTable } from 'hooks/useLayoutPaginatedTable';
import { getFirstShipmentDetails } from 'queries/salesOrders/mappers';
import { useMemo } from 'react';
import { Column, Row } from 'react-table';
import { QueryState } from 'types/queryState';
import { SalesOrder } from 'types/salesOrders';
import { formatDate } from 'utils/dateUtils';

interface Props extends SpaceProps {
  order?: SalesOrder;
}

export function OrderInfoTable({ order }: Props) {
  const { getColumnWidth } = useLayoutPaginatedTable(1190);

  const orderInfoColumns = useMemo(
    () =>
      [
        {
          Header: 'Created Date',
          accessor: 'createdDate',
          width: getColumnWidth(10, 120),
          Cell: ({ value: createdDate }: { value: string }) => (
            <>{formatDate(createdDate)}</>
          ),
        },
        {
          Header: 'Ship Via',
          accessor: 'shipVia',
          width: getColumnWidth(20, 120),
        },
        order?.type === 'Parcel'
          ? {
              Header: 'Carrier',
              width: getColumnWidth(10, 120),
              Cell: ({ row }: { row: Row<SalesOrder> }) => (
                <>
                  {getFirstShipmentDetails(row.original?.trackingNumbersDetail)
                    ?.carrier ?? ''}
                </>
              ),
            }
          : null,
        order?.type === 'Parcel'
          ? {
              Header: 'Carrier Service',
              width: getColumnWidth(15, 180),
              Cell: ({ row }: { row: Row<SalesOrder> }) => (
                <>
                  {getFirstShipmentDetails(row.original?.trackingNumbersDetail)
                    ?.service ?? ''}
                </>
              ),
            }
          : null,
        {
          Header: 'Reference Number',
          accessor: 'poNumber',
          width: getColumnWidth(order?.type === 'Parcel' ? 15 : 25, 200),
        },
        {
          Header: 'Customer Ref #',
          accessor: 'customerNumber',
          width: getColumnWidth(order?.type === 'Parcel' ? 20 : 35, 200),
          Cell: ({ value: customerNumber }: { value?: string }) => (
            <ToggleVisibility bypass={!customerNumber?.trim()} mr={-1}>
              {customerNumber}
            </ToggleVisibility>
          ),
        },
        {
          Header: 'Customer Name',
          accessor: 'customerName',
          width: getColumnWidth(20, 250),
          Cell: ({ value: customerName }: { value?: string }) => (
            <ToggleVisibility mr={-1}>{customerName}</ToggleVisibility>
          ),
        },
      ].filter(Boolean) as Column<any>[],
    [order, getColumnWidth]
  );

  return (
    <PaginatedTable
      columns={orderInfoColumns}
      queryState={
        {
          data: order ? [order] : [],
          isLoading: !order,
        } as QueryState<SalesOrder>
      }
      tableName="order_info_table"
      isResizable
    />
  );
}
