import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { ItemMasterCustomsInfo } from 'types/itemMaster';

type Props = {
  data: ItemMasterCustomsInfo[];
};

export function AccordionItemMasters({ data }: Props) {
  const previewItems = useMemo(() => {
    return [
      {
        label: 'New SKUs',
        skuList: data.filter((item) => !item.isValidItemMaster),
      },
      {
        label: 'Updated SKUs',
        skuList: data.filter((item) => item.isValidItemMaster),
      },
    ].filter((item) => item.skuList.length > 0);
  }, [data]);

  return (
    <Accordion width={['100%', '400px']} allowMultiple defaultIndex={[0]}>
      {previewItems.map((item) => (
        <AccordionItem key={item.label}>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              {item.label}
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <UnorderedList fontWeight={300}>
              {item.skuList.map((sku) => (
                <ListItem key={sku.sku}>{sku.sku}</ListItem>
              ))}
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
}
