import {
  FormSelect,
  FormSelectOption,
  FormSelectProps,
} from 'components/FormSelect';
import { useCarrierAccounts } from 'queries/shipVias/useCarrierAccounts';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { SELECT_NODE_OPTION } from 'queries/warehouses/useWarehouses';
import uniqBy from 'lodash-es/uniqBy';
import isEqual from 'lodash-es/isEqual';
import { useUpdateEffect } from '@chakra-ui/react';

export const usAndTerritories = ['US', 'PR'];
export const defaultShippingAccountCountry = 'US';

function CarrierAccountSelect(props: Omit<FormSelectProps, 'options'>) {
  const [options, setOptions] = useState<FormSelectOption[]>([]);
  const { register, watch, setValue, getValues } = useFormContext();

  const { data = [] } = useCarrierAccounts();

  const warehouseID = watch('warehouseID');
  const country = watch('shipTo.country');

  useEffect(() => {
    const carrierAccounts = data
      .filter((item) => {
        const isValidCountry = usAndTerritories.includes(country)
          ? item.countryCode === country
          : item.countryCode === defaultShippingAccountCountry;
        return (
          isValidCountry &&
          (!warehouseID ||
            warehouseID === SELECT_NODE_OPTION.code ||
            item.distributionCenterCode === warehouseID)
        );
      })
      .map((item) => ({
        value: item.carrierAccountUuid,
        label: item.carrierAccountUuid,
      }));

    const newOptions = uniqBy(carrierAccounts, 'value');

    if (!isEqual(options, newOptions)) {
      setOptions(newOptions);
    }
  }, [data, warehouseID, country, options]);

  useUpdateEffect(() => {
    if (options.length === 1) {
      setValue('carrierAccountUUID', options[0].value!, {
        shouldDirty: true,
        shouldValidate: true,
      });
    } else if (
      !options.some(
        (option) => option.value === getValues('carrierAccountUUID')
      )
    ) {
      setValue('carrierAccountUUID', '', {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return (
    <FormSelect
      {...register('carrierAccountUUID')}
      key={`carrierAccountUUID-${options.length}`}
      options={options}
      label="Carrier Billing Account"
      isRequired={
        typeof props.isRequired === 'boolean'
          ? props.isRequired
          : warehouseID !== SELECT_NODE_OPTION.code
      }
      isDisabled={options.length === 1}
      tooltip="Select the default, unless this order requires a 3rd party billing (3P) option."
      {...props}
    />
  );
}

export default CarrierAccountSelect;
