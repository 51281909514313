import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import {
  PurchaseOrderPreview,
  PurchaseOrderUploadError,
} from 'types/purchaseOrders';
import { mapDtoToPurchaseOrderUploadError } from './mappers';

export function useUploadPurchaseOrders() {
  const queryClient = useQueryClient();
  return useMutation<
    any,
    PurchaseOrderUploadError[],
    { warehouseCode: string; file: File; data: PurchaseOrderPreview[] }
  >({
    mutationFn: async ({ warehouseCode, file, data }) => {
      const formData = new FormData();
      formData.append('file', file);
      try {
        const response = await axios.post(
          '/purchaseorder/bulk-po-upload',
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
            params: { target_dc: warehouseCode },
          }
        );
        return response.data;
      } catch (error: any) {
        const errors = (error as AxiosError<any>)?.response?.data?.errors ?? [];
        throw mapDtoToPurchaseOrderUploadError(errors, data);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['purchaseOrders'] });
    },
  });
}
